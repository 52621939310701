/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserScopeFilterOrder } from './userScopeFilterOrder';
import { UserFields } from './userFields';
import { UserIncludeFilterInner } from './userIncludeFilterInner';


export interface UserFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: UserScopeFilterOrder;
    where?: { [key: string]: any; };
    fields?: UserFields;
    include?: Array<UserIncludeFilterInner>;
}

