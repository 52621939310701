<div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Správce souborů</h2>
    <button class="text-secondary" (click)="close()" mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
</div>

<mat-divider class="text-border"></mat-divider>

<div mat-dialog-content class="flex flex-col">


    @if(!loading) {

      <div class="flex flex-row p-2">
        <div>
          <button mat-icon-button (click)="addAsset()" matTooltip="Přidat soubor">
            <mat-icon svgIcon="mat:upload_file"></mat-icon>
          </button>
        </div>
      </div>

      <div class="flex flex-row flex-wrap place-items-center gap-3">
      @for(asset of assets; track asset) {
        <div class="relative p-2 bg-gray-100 rounded-2xl">
            <img (click)="openAsset(asset)" [src]="asset | photoProxy:'rs:fit:400:400:1/g:no':'' | async" class="block h-24 w-24 object-cover object-center cursor-pointer" alt="asset" />
<!--            <button mat-icon-button class="absolute top-2 right-2">-->
<!--              <mat-icon svgIcon="mat:delete"></mat-icon>-->
<!--            </button>-->
<!--          <p-image [preview]="true" [src]="asset | photoProxy:'rs:fit:400:400:1/g:no':'' | async" [alt]="asset" class="block h-full w-full object-cover object-center cursor-pointer" width="250"></p-image>-->
        </div>
      }
      </div>
    } @else {
      <div class="mt-6 flex flex-col flex-wrap items-center justify-center">
        <mat-spinner></mat-spinner>
      </div>
    }

<!--    <form [formGroup]="form">-->
<!--      <div class="flex flex-col sm:flex-row sm:gap-4">-->
<!--          <mat-form-field class="flex-auto">-->
<!--            <mat-label>Název</mat-label>-->
<!--            <input formControlName="name" matInput>-->
<!--          </mat-form-field>-->
<!--          <mat-form-field class="flex-auto">-->
<!--            <mat-label>Kód</mat-label>-->
<!--            <input formControlName="code" matInput>-->
<!--            <mat-icon class="mr-3" matPrefix svgIcon="mat:code"></mat-icon>-->
<!--          </mat-form-field>-->
<!--      </div>-->
<!--    </form>-->

</div>

<div mat-dialog-actions align="end">
<!--  <button mat-flat-button (click)="close()" type="button">Zavřít</button>-->
  <button mat-flat-button color="primary" (click)="close()" type="submit">Zavřít</button>
</div>
