/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<Partner, \'id\'>, schemaOptions: { title: \'NewPartner\', exclude: [ \'id\' ] })
 */
export interface NewPartner { 
    name: string;
    filename?: string | null;
    link?: string | null;
    visible?: boolean;
    rank?: number;
}

