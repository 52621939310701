<h1 mat-dialog-title>Nahrání souboru k aukci</h1>
<div mat-dialog-content>
          <mat-tab-group mat-stretch-tabs>
            <mat-tab *ngFor="let l of auctionFile.langs">
              <ng-template mat-tab-label>
                <mat-icon svgIcon="flag:{{langService.getLangsShortCode(l.langId)}}"></mat-icon>
                <span class="pl-2">{{langService.getLangsShortCode(l.langId)}}</span>
              </ng-template>
              <div class="flex flex-col items-center m-2">
                <div>
                  <p-fileUpload
                    #auctionFileUpload
                    mode="basic"
                    [customUpload]="true"
                    (uploadHandler)="processUpload(l.langId, $event, auctionFileUpload)"
                    [name]="'auctionfile'"
                    [auto]="true"
                    accept="image/*,application/pdf"
                    chooseLabel="Nahrát soubor">
                  </p-fileUpload>
<!--                  <small *ngIf="l.filename">{{l.filename?.name}}</small>-->
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>


  <form class="flex-auto m-2" [formGroup]="form">
    <mat-form-field class="flex flex-col">
      <mat-label>Typ souboru</mat-label>
      <mat-select formControlName="type" placeholder="Vyberte typ souboru">
        @for(type of filesTypeCs; track type) {
          <mat-option value="{{type}}">{{auctionFileTypes[type]}}</mat-option>
        }
      </mat-select>
      <mat-icon class="mr-3" matPrefix svgIcon="mat:file_present"></mat-icon>
      <mat-error *ngIf="form.controls.type.hasError('exist')">Tato typ souboru již byl nahrán. Upravit soubor lze na detailu aukce.</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions cdkTrapFocus class="flex flex-row-reverse">
  <button mat-flat-button color="accent"  class="m-2" (click)="save()" [disabled]="!valid() || uploading">Přidat</button>
  <button mat-flat-button color="primary" class="m-2" (click)="dialogRef.close(false)" cdkFocusInitial>Zrušit</button>
</div>
