/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface DownloadItem { 
    id?: number;
    filename?: string | null;
    mime?: string | null;
    visible?: boolean;
    created?: string;
}

