/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TeamMemberLangExcludingCreatorCreatedLangWithRelations } from './teamMemberLangExcludingCreatorCreatedLangWithRelations';
import { TeamProjectExcludingCreatorCreatedLangWithRelations } from './teamProjectExcludingCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<TeamMemberWithRelations, \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'creator\', \'created\', \'lang\' ] })
 */
export interface TeamMemberExcludingCreatorCreatedLangWithRelations { 
    id?: number;
    firstname: string;
    lastname: string;
    titleBefore?: string | null;
    titleAfter?: string | null;
    email?: string;
    showMail?: boolean;
    maternityLeave?: boolean;
    filename?: string | null;
    order?: number | null;
    visible?: boolean;
    lang?: TeamMemberLangExcludingCreatorCreatedLangWithRelations;
    langs?: Array<TeamMemberLangExcludingCreatorCreatedLangWithRelations>;
    projects?: Array<TeamProjectExcludingCreatorCreatedLangWithRelations>;
}

