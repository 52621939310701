/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionLangExcludingLangsWithRelations } from './auctionLangExcludingLangsWithRelations';
import { PressReleaseExcludingLangsWithRelations } from './pressReleaseExcludingLangsWithRelations';
import { AuctionStatusExcludingLangsWithRelations } from './auctionStatusExcludingLangsWithRelations';
import { ExhibitionExcludingLangsWithRelations } from './exhibitionExcludingLangsWithRelations';
import { ItemExcludingLangsWithRelations } from './itemExcludingLangsWithRelations';
import { AuctionFileExcludingLangsWithRelations } from './auctionFileExcludingLangsWithRelations';


/**
 * (tsType: Omit<AuctionWithRelations, \'langs\'>, schemaOptions: { title: \'FrontendAuction\', includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface FrontendAuction { 
    id?: number;
    auctionNumber?: string | null;
    internetAuction?: boolean;
    fee?: number | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    youtubeVideoId?: string | null;
    mainAuction?: boolean;
    archived?: boolean;
    canvasId?: number | null;
    artslimitId?: number | null;
    exhibitionId?: number | null;
    canOrderCatalog?: boolean | null;
    visible?: boolean;
    created?: string;
    status?: number;
    auctionStatus?: AuctionStatusExcludingLangsWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingLangsWithRelations;
    files?: Array<AuctionFileExcludingLangsWithRelations>;
    lang?: AuctionLangExcludingLangsWithRelations;
    langs?: Array<AuctionLangExcludingLangsWithRelations>;
    items?: Array<ItemExcludingLangsWithRelations>;
    pressReleases?: Array<PressReleaseExcludingLangsWithRelations>;
}

