<div class="p-4">
<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">Nahrání souboru</h2>
  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>

<mat-divider class="text-border"></mat-divider>

<div class="flex flex-col p-2">
  @if(!loading) {
      <div class="flex flex-col gap-2 place-content-center items-center m-4">
        @if(fileUrl) {
          <p-image [preview]="true" [src]="fileUrl" class="block h-full w-full object-cover object-center cursor-pointer" width="250"></p-image>
        }
        <p-fileUpload mode="basic" #imageModalButton [customUpload]="true" (uploadHandler)="processUpload($event, imageModalButton)" [name]="'blockimage'" [auto]="true" accept="image/*" chooseLabel="Nahrát obrázek"></p-fileUpload>
      </div>
      <div class="flex w-full items-center text-center">
      </div>
  }@else{
    <div class="mt-6 flex flex-col flex-wrap items-center justify-center">
      <mat-spinner></mat-spinner>
    </div>
  }
</div>

<div class="flex flex-row-reverse gap-4">
  <button color="primary" mat-flat-button (click)="save()" type="submit">Nahrát</button>
  <button mat-button mat-dialog-close type="button">Zavřít</button>
</div>
</div>
