/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface Item { 
    id?: number;
    auctionId?: number;
    exhibitionId?: number;
    size?: string | null;
    priceEstimationLow?: number | null;
    priceEstimationHigh?: number | null;
    priceCurrent?: number | null;
    priceHammer?: number | null;
    priceFinal?: number | null;
    startPriceForBidding?: number | null;
    startPriceForClosing?: number | null;
    catalogueNumber?: number | null;
    canvasId?: number | null;
    artslimitId?: number | null;
    created?: string;
    lastModified?: string;
    status?: number;
}

