/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SliderItemLangWithRelations } from './sliderItemLangWithRelations';


/**
 * (tsType: SliderItemWithRelations, schemaOptions: { includeRelations: true })
 */
export interface SliderItemWithRelations { 
    id?: number;
    sliderId: number;
    filename?: string;
    filenameMobile?: string | null;
    targetType?: SliderItemWithRelations.TargetTypeEnum | null;
    targetWindow?: string | null;
    targetId?: string | null;
    resizingType?: string | null;
    boxLocation?: SliderItemWithRelations.BoxLocationEnum | null;
    url?: string | null;
    rank?: number;
    visible?: boolean | null;
    lang?: SliderItemLangWithRelations;
    langs?: Array<SliderItemLangWithRelations>;
}
export namespace SliderItemWithRelations {
    export type TargetTypeEnum = 'url' | 'page' | 'news' | '1' | '2' | '3';
    export const TargetTypeEnum = {
        Url: 'url' as TargetTypeEnum,
        Page: 'page' as TargetTypeEnum,
        News: 'news' as TargetTypeEnum,
        _1: '1' as TargetTypeEnum,
        _2: '2' as TargetTypeEnum,
        _3: '3' as TargetTypeEnum
    };
    export type BoxLocationEnum = 'tl' | 'tr' | 'bl' | 'br' | 'mid' | '1' | '2' | '3' | '4' | '5';
    export const BoxLocationEnum = {
        Tl: 'tl' as BoxLocationEnum,
        Tr: 'tr' as BoxLocationEnum,
        Bl: 'bl' as BoxLocationEnum,
        Br: 'br' as BoxLocationEnum,
        Mid: 'mid' as BoxLocationEnum,
        _1: '1' as BoxLocationEnum,
        _2: '2' as BoxLocationEnum,
        _3: '3' as BoxLocationEnum,
        _4: '4' as BoxLocationEnum,
        _5: '5' as BoxLocationEnum
    };
}


