/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PressReleaseExcludingIdCreatorCreatedLangWithRelations } from './pressReleaseExcludingIdCreatorCreatedLangWithRelations';
import { AuctionStatusExcludingIdCreatorCreatedLangWithRelations } from './auctionStatusExcludingIdCreatorCreatedLangWithRelations';
import { ItemExcludingIdCreatorCreatedLangWithRelations } from './itemExcludingIdCreatorCreatedLangWithRelations';
import { AuctionFileExcludingIdCreatorCreatedLangWithRelations } from './auctionFileExcludingIdCreatorCreatedLangWithRelations';
import { AuctionLangExcludingIdCreatorCreatedLangWithRelations } from './auctionLangExcludingIdCreatorCreatedLangWithRelations';
import { ExhibitionExcludingIdCreatorCreatedLangWithRelations } from './exhibitionExcludingIdCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<AuctionWithRelations, \'id\' | \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'creator\', \'created\', \'lang\' ] })
 */
export interface AuctionExcludingIdCreatorCreatedLangWithRelations { 
    auctionNumber?: string | null;
    internetAuction?: boolean;
    fee?: number | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    youtubeVideoId?: string | null;
    mainAuction?: boolean;
    archived?: boolean;
    canvasId?: number | null;
    artslimitId?: number | null;
    exhibitionId?: number | null;
    canOrderCatalog?: boolean | null;
    visible?: boolean;
    status?: number;
    auctionStatus?: AuctionStatusExcludingIdCreatorCreatedLangWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingIdCreatorCreatedLangWithRelations;
    files?: Array<AuctionFileExcludingIdCreatorCreatedLangWithRelations>;
    lang?: AuctionLangExcludingIdCreatorCreatedLangWithRelations;
    langs?: Array<AuctionLangExcludingIdCreatorCreatedLangWithRelations>;
    items?: Array<ItemExcludingIdCreatorCreatedLangWithRelations>;
    pressReleases?: Array<PressReleaseExcludingIdCreatorCreatedLangWithRelations>;
}

