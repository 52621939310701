<h1 mat-dialog-title>Vyberte jazykovou mutaci</h1>
<div mat-dialog-content>
  <form class="flex-auto" [formGroup]="form">
    <mat-form-field class="flex flex-col">
    <mat-label>Jazykový mutace</mat-label>
    <mat-select formControlName="langId">
      <mat-option *ngFor="let l of langs" [value]="l.id">{{l.name}}</mat-option>
    </mat-select>
    <mat-icon class="mr-3" matPrefix svgIcon="mat:language"></mat-icon>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions cdkTrapFocus class="flex flex-row items-center">
  <button mat-flat-button color="primary" class="m-5" (click)="dialogRef.close(false)" cdkFocusInitial>Zrušit</button>
  <button mat-flat-button color="accent"  class="m-5" (click)="addLang()" [disabled]="(form.value.langId === null) ? true : false">Přidat</button>
</div>
