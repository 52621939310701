/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ExhibitionLangWithRelations } from './exhibitionLangWithRelations';
import { AuctionWithRelations } from './auctionWithRelations';
import { ItemWithRelations } from './itemWithRelations';
import { PressReleaseWithRelations } from './pressReleaseWithRelations';


/**
 * (tsType: ExhibitionWithRelations, schemaOptions: { includeRelations: true })
 */
export interface ExhibitionWithRelations { 
    id?: number;
    auctionId?: number;
    exhibitionFrom: string;
    exhibitionTo: string;
    mainExhibition?: boolean;
    matterportId?: string | null;
    youtubeVideoId?: string | null;
    archived?: boolean;
    status?: number;
    visible?: boolean;
    showPrices?: boolean | null;
    created?: string;
    lastModified?: string | null;
    auction?: AuctionWithRelations;
    foreignKey?: any | null;
    lang?: ExhibitionLangWithRelations;
    langs?: Array<ExhibitionLangWithRelations>;
    items?: Array<ItemWithRelations>;
    pressReleases?: Array<PressReleaseWithRelations>;
}

