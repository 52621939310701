import { Pipe, PipeTransform } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ImgControllerService} from "../../../@backend";
import {AuthenticationService} from '../services/authentication.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'photo'
})
export class PhotoPipe implements PipeTransform {


  apiUrl: string = environment.baseApiUrl;
  constructor(private imgService: ImgControllerService, private http: HttpClient, private authService: AuthenticationService, private sanitizer: DomSanitizer) {
  }

  /**
   *
   * @param
   * @param
   * @param
   */
  async transform(filename: string, modifiers: string = "/rs:fill:500:500:1/g:no/", path: string = 'user'): Promise<SafeHtml> {

    if(filename) {
      const imageSrc = this.apiUrl + '/img/' + path + '/' + encodeURIComponent(modifiers) + "/" + filename;
      const token = this.authService.currentUser.token;
      const headers = new HttpHeaders({Authorization: `Bearer ${token}`});
      const imageBlob = await this.http.get(imageSrc, {headers, responseType: 'blob'}).toPromise();

      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            let result = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result);
            resolve(result);
          }
        }
        reader.readAsDataURL(imageBlob);
      });
    }else{
      return '';
    }


  //   const parsed = JSON.parse(value);
  //   let image = parsed.filter(x => x.type === type) as any; // ARRAY
  //   if(image.length === 0) { // Nenašli jsme titulní fotku, dáme aspon pracovni
  //     type = "0";
  //     image = parsed.filter(x => x.type === type) as any; // ARRAY
  //   }
  //   let imageSrc = '' as string;
  //   let imageOrig = '' as string;
  //
  //   if(image.length > 0) {
  //     // Občas je tam více označených obrázků jako hlavních
  //     image = image.shift();
  //     imageOrig = 's3://canvas.prague/paintings/' + image.filename;
  //     imageSrc = cdnUrl + '/plain/rs:fill:500:500:1/g:no/' + btoa(imageOrig) + ".webp";
  //   }else{
  //     // imageSrc =  cdnUrl + '/assets/images/design/painting_default.jpg';
  //     imageSrc = 'https://canvas.galeriekodl.cz' + '/assets/images/design/painting_default.jpg';
  //   }
  //   return imageSrc;
  // }
  }
}
