import { Component, Inject, OnInit } from '@angular/core';
import {FormControl, FormGroup, UntypedFormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LangsService} from '../../../shared/services/langs.service';
import {
  AdminService, AuthorLangWithRelations, AuthorWithRelations, CanvasImporterService, CanvasAuthorWithRelations
} from "../../../../@backend";
import {ConfirmDialog} from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {TinyService} from '../../../shared/services/tiny.service';

@Component({
  selector: 'kodl-author-create-edit-modal',
  templateUrl: './author-create-edit-modal.component.html',
  styleUrls: ['./author-create-edit-modal.component.scss']
})
export class AuthorCreateEditModalComponent implements OnInit {

  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  author: AuthorWithRelations;
  canvasAuthors: CanvasAuthorWithRelations[];
  canvasAuthorsFiltered: CanvasAuthorWithRelations[];
  canvasAuthorFilter = new FormControl('');
  canvasAuthorSelected: number | null = null;
  loading: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any | null,
              private dialogRef: MatDialogRef<AuthorCreateEditModalComponent>,
              private dialog: MatDialog,
              public langsService: LangsService,
              private adminService: AdminService,
              private canvasService: CanvasImporterService,
              private fb: UntypedFormBuilder,
              public tinyService: TinyService,
              private snackBar: MatSnackBar) {
    if(data === null) {
      this.mode = 'create';
      this.author = {langs: [] as AuthorLangWithRelations[]} as AuthorWithRelations;
      for(const lang of this.langsService.langs) {
        this.author.langs.push({langId: lang.id} as AuthorLangWithRelations);
      }
      this.loading = false;
    }else if (data?.authorId){
      this.mode = 'update';
      this.init(data.authorId);
    }
  }


  async init(authorId: number) {
    this.loading = true;
    this.author = await this.adminService.authorControllerAdminGetAuthor(authorId).toPromise();
    this.form.patchValue(this.author);
    if(this.author.canvasId === null) {
      this.canvasAuthors = await this.canvasService.canvasImporterControllerGetAllAuthors().toPromise();
      this.canvasAuthorsFiltered = this.canvasAuthors;
    }
    this.loading = false;
  }
  ngOnInit() {
    this.form = this.fb.group({
      firstname: [null],
      lastname: [null],
      birthday: [null],
      death: [null],
      nickname: ['' || null],
    });
  }


  filter($event: any): void {
    const filterValue = $event.target.value.toLowerCase();
    this.canvasAuthorsFiltered = this.canvasAuthors.filter((a) => {
      const dataStr = (
        a.firstname +
        a.lastname +
        a.nickname
      ).trim().toLowerCase();
      return dataStr.indexOf(filterValue) != -1;
      });
  }

  async save() {
    if(this.form.valid) {
      try {
        const newAuthor = { ...this.author, ...this.form.value };
        if(this.canvasAuthorSelected) {
          newAuthor.canvasId = this.canvasAuthorSelected;
        }
        if(this.mode === 'update') {
          const res = await this.adminService.authorControllerAdminSaveAuthor(this.author.id, newAuthor).toPromise();
          this.dialogRef.close(res);
        }else if(this.mode === 'create'){
          const res = await this.adminService.authorControllerAdminCreateAuthor(newAuthor).toPromise();
          this.dialogRef.close(res.id);
        }

      } catch (e) {
        console.log(e);
      }
    }else{
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }

  }

  isValid(): boolean {
    if(this.form.valid && this.customValidation()){
      return true;
    }else{
      return false;
    }
  }

  customValidation(): boolean {
    // for (const lang of this.author.langs) {
      // if(lang.description === null) {
      //   return false;
      // }
    // }
    return true;
  }


  delete() {
    let dialogRef: MatDialogRef<ConfirmDialog>;

    dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.componentInstance.title = 'Odstranění autora';
    dialogRef.componentInstance.message = 'Opravdu chcete odstranit autora '+ this.author.firstname + ' ' + this.author.lastname +'?';
    dialogRef.componentInstance.confirmText = 'Ano';

    dialogRef.afterClosed().subscribe(async result => {
      if(result) {
        try {
          await this.adminService.authorControllerAdminDeleteAuthor(this.author.id).toPromise();
          this.dialogRef.close(true);
        }catch (e) {
          this.snackBar.open('Chyba: ' + e.error.error.message, "OK", {duration: 2000, panelClass: ['bg-red-600']});
        }
      }
    });
  }


  async updateFromCanvas() {
    await this.adminService.canvasImporterControllerGetAuthor(this.author.canvasId).toPromise();
    this.init(this.author.id);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }


  onSelectAuthor($event: MatAutocompleteSelectedEvent) {
    const author = $event.option.value as CanvasAuthorWithRelations;
    this.canvasAuthorSelected = author.id;
    this.form.patchValue(author);
    if(this.author.langs[0]?.langId === 1) {
      this.author.langs[0].description = author.descriptionCs;
    }else {
      this.author.langs[0].description = author.descriptionEn;
    }

    if(this.author.langs[1]?.langId === 2) {
      this.author.langs[1].description = author.descriptionEn;
    }else {
      this.author.langs[1].description = author.descriptionCs;
    }


    this.canvasAuthorFilter.setValue(author && author.nickname ? author.nickname  : author.firstname + ' ' + author.lastname);
  }
}
