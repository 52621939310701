/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<NewFormItemOffer, \'id\' | \'langId\' | \'status\' | \'created\' | \'isProcessing\'>, schemaOptions: { exclude: [ \'id\', \'langId\', \'status\', \'created\', \'isProcessing\' ], includeRelations: false })
 */
export interface NewFormItemOfferExcludingIdLangIdStatusCreatedIsProcessing { 
    name: string;
    email: string;
    telephone: string;
    itemAuthor: string;
    itemTechnique?: string | null;
    itemSize?: string;
    itemDescription?: string | null;
    acquisitionType: NewFormItemOfferExcludingIdLangIdStatusCreatedIsProcessing.AcquisitionTypeEnum;
    acquisitionOther?: string | null;
    recaptchaToken: string;
}
export namespace NewFormItemOfferExcludingIdLangIdStatusCreatedIsProcessing {
    export type AcquisitionTypeEnum = 'heritage' | 'purchase' | 'restitution' | 'other';
    export const AcquisitionTypeEnum = {
        Heritage: 'heritage' as AcquisitionTypeEnum,
        Purchase: 'purchase' as AcquisitionTypeEnum,
        Restitution: 'restitution' as AcquisitionTypeEnum,
        Other: 'other' as AcquisitionTypeEnum
    };
}


