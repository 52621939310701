/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: MenuItemLangWithRelations, schemaOptions: { includeRelations: true })
 */
export interface MenuItemLangWithRelations { 
    id?: number;
    menuItemId: number;
    langId: number;
    title?: string | null;
}

