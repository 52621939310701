/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ExhibitionLangExcludingIdWithRelations } from './exhibitionLangExcludingIdWithRelations';
import { PressReleaseExcludingIdWithRelations } from './pressReleaseExcludingIdWithRelations';
import { ItemExcludingIdWithRelations } from './itemExcludingIdWithRelations';
import { AuctionExcludingIdWithRelations } from './auctionExcludingIdWithRelations';


/**
 * (tsType: Omit<ExhibitionWithRelations, \'id\'>, schemaOptions: { title: \'NewExhibition\', exclude: [ \'id\' ], includeRelations: true })
 */
export interface NewExhibition { 
    auctionId?: number;
    exhibitionFrom: string;
    exhibitionTo: string;
    mainExhibition?: boolean;
    matterportId?: string | null;
    youtubeVideoId?: string | null;
    archived?: boolean;
    status?: number;
    visible?: boolean;
    showPrices?: boolean | null;
    created?: string;
    lastModified?: string | null;
    auction?: AuctionExcludingIdWithRelations;
    foreignKey?: any | null;
    lang?: ExhibitionLangExcludingIdWithRelations;
    langs?: Array<ExhibitionLangExcludingIdWithRelations>;
    items?: Array<ItemExcludingIdWithRelations>;
    pressReleases?: Array<PressReleaseExcludingIdWithRelations>;
}

