/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<AuthorLangWithRelations, \'id\' | \'visible\' | \'status\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'visible\', \'status\' ] })
 */
export interface AuthorLangExcludingIdVisibleStatusWithRelations { 
    authorId?: number | null;
    langId: number;
    description?: string | null;
}

