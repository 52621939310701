/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { SliderItemWithRelations } from './sliderItemWithRelations';


/**
 * (tsType: SliderWithRelations, schemaOptions: { includeRelations: true })
 */
export interface SliderWithRelations { 
    id?: number;
    name: string;
    code: string;
    visible?: boolean;
    items?: Array<SliderItemWithRelations>;
}

