import { BrowserModule } from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import {BASE_PATH} from "../@backend";
import {JwtInterceptor} from './shared/interceptors/jwt.interceptor';
import {ErrorInterceptor} from './shared/interceptors/error.interceptor';
import {environment} from '../environments/environment';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import {AuthenticationService} from './shared/services/authentication.service';
import {StringService} from './shared/services/string.service';
import {LangsService} from './shared/services/langs.service';
import {PendingChangesGuard} from './shared/guard/pending-changes.guard';
import {AuthGuard} from './shared/guard/auth.guard';
registerLocaleData(localeCs, 'cs');


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Vex
    VexModule,
    MatSnackBarModule,
    CustomLayoutModule,


    // TODO: DELETE
    MatIconModule,
    MatButtonModule,

  ],
  providers: [
    AuthenticationService,
    StringService,
    LangsService,
    PendingChangesGuard,
    AuthGuard,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: BASE_PATH, useValue: environment.baseApiUrl},
    {provide: MAT_DATE_LOCALE, useValue: 'cs' },
    {provide: LOCALE_ID, useValue: 'cs' },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'Kč'}
  ],
  exports: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
