/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PageBlockWithRelations } from './pageBlockWithRelations';
import { PageLangWithRelations } from './pageLangWithRelations';


/**
 * (tsType: PageWithRelations, schemaOptions: { title: \'Page\', includeRelations: true })
 */
export interface Page { 
    id?: number;
    name: string;
    slug: string;
    pageType?: number;
    robots?: string;
    visible?: boolean;
    systemPage?: boolean | null;
    renderToSitemap?: boolean;
    lft?: number;
    rgt?: number;
    lang?: PageLangWithRelations;
    langs?: Array<PageLangWithRelations>;
    pageBlocks?: Array<PageBlockWithRelations>;
}

