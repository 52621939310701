export const environment = {
  production: false,
  isLocal: false,
  name: 'UAT',
  auth2FA: true,
  frontendUrl: 'https://galeriekodl.uat.bckpp.com',
  baseAdminUrl: 'https://admin.galeriekodl.uat.bckpp.com',
  baseApiUrl: 'https://api.galeriekodl.uat.bckpp.com',
  webCdnUrl: 'https://web.cdn.dev.bckpp.com',
  sharedCdnUrl: 'https://shared.cdn.dev.bckpp.com'
};
