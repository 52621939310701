import {Component, Inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ComponentsModule} from '../components.module';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {environment} from "../../../../environments/environment";
import {MatFormField} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatOption, MatSelect} from "@angular/material/select";
import {generateCdnUrl} from "../../utils/generateCdnUrl";
import {ConfirmDialog} from "../confirm-dialog/confirm-dialog.component";

export interface Asset {
  url?: string;
  width: number;
  height: number;
  resizingType: string;
  filename: string;
}

@Component({
  selector: 'canvas-asset-preview',
  standalone: true,
  imports: [CommonModule, ComponentsModule, MatButtonModule, MatIconModule, MatFormField, MatInputModule, FormsModule, ReactiveFormsModule, MatSelect, MatOption],
  template: `
  <div class="flex relative flex-col p-2 w-full gap-2" style="max-height: 60%">
    <button mat-icon-button class="absolute top-2 right-2 text-white" (click)="close()"><mat-icon>close</mat-icon></button>

    <img [src]="url" style="height: 60%; max-height: 60%;" class="block h-full w-full object-cover object-center cursor-pointer" />

    <div class="flex justify-center items-center gap-4">
        <mat-form-field>
            <mat-label>Šířka</mat-label>
            <input matInput [(ngModel)]="width">
        </mat-form-field>

      <mat-form-field>
        <mat-label>Výška</mat-label>
        <input matInput [(ngModel)]="height">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Výška</mat-label>
        <mat-select [(ngModel)]="resizingType">
          @for(type of resizingTypes; track type){
            <mat-option [value]="type.value">{{type.cs}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="flex justify-center items-center gap-5">
<!--      <button (click)="deleteAsset()" mat-icon-button color="warn"><mat-icon svgIcon="mat:delete"></mat-icon></button>-->
        @if(mode === 'edit') {
          <button (click)="addAsset()" mat-flat-button color="primary" class="flex items-center"><mat-icon svgIcon="mat:edit"></mat-icon> <span>Upravit</span></button>
        } @else {
          <button (click)="addAsset()" mat-flat-button color="primary" class="flex items-center"><mat-icon svgIcon="mat:add"></mat-icon> <span>Přidat</span></button>
        }
    </div>
  </div>
  `
})
export class AssetPreviewComponent implements OnInit {

  filename: string;
  url: string;
  mode: 'edit' | 'add' = 'edit';
  webCdnUrl: string = environment.webCdnUrl;
  directS3Url: string = 'https://prague.s3.tuxit.cz/web.prague/'
  modifiers: string = "rs:fit:240:200:1/g:no"

  width: number = 400;
  height: number = 400;
  resizingType: string = 'fit';
  resizingTypes = [
    {value: 'fit', cs: 'Přizpůsobit'},
    {value: 'fill', cs: 'Vyplnit'},
    {value: 'fill-down', cs: 'Vyplnit dolů'},
    {value: 'force', cs: 'Vynutit'},
    {value: 'auto', cs: 'Automaticky'},
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Asset,
    public dialogRef: MatDialogRef<AssetPreviewComponent>,
    private dialog: MatDialog) {
    this.filename = this.data?.filename ?? undefined;
    this.url = this.data?.url ?? undefined;
    if(this.url === undefined) {
      this.mode = 'add';
      this.url = generateCdnUrl(this.filename, this.resizingType, this.width, this.height, this.webCdnUrl);

      const directPathToImage = this.directS3Url + '/' + this.filename;
      this.getImageDimensions(directPathToImage).then((dimensions) => {
        this.width = dimensions.width;
        this.height = dimensions.height;
      });
    }else {
      this.mode = 'edit';
      if(this.data?.filename) {
        this.width = this.data?.width;
        this.height = this.data?.height;
        this.resizingType = this.data?.resizingType ?? 'fit';
        this.filename = this.data?.filename;
      }else {
        // For images that are not added by the asset manager
        this.width = this.data?.width;
        this.height = this.data?.height;
        this.getImageDimensions(this.url).then((dimensions) => {
          this.width = dimensions.width;
          this.height = dimensions.height;
        });
        this.getFilenameFromUrl();
      }
    }
  }

  async ngOnInit() {
  }

  getFilenameFromUrl() {
    let filename = undefined;
    try {
      filename = atob(this.url.split('/').pop());
      filename = filename.split('/').pop();
      this.filename = 'assets/' + filename;
    } catch (e) {
      filename = this.url.split('/').pop();
      this.filename = 'assets/' + filename;
    }
  }

  async deleteAsset() {

    this.dialog.open(ConfirmDialog, {
      data: {
        title: 'Odstranění fotky',
        message: 'Opravdu chcete odstranit fotku?',
        confirmText: 'Ano'
      }
    }).afterClosed().subscribe(async result => {

    });
  }


  addAsset() {
    const url = generateCdnUrl(this.filename, this.resizingType, this.width, this.height, this.webCdnUrl);
    this.dialogRef.close({
      url, width: this.width, height: this.height, resizingType: this.resizingType, filename: this.filename
    } as Asset);
  }

  close() {
    this.dialogRef.close(false)
  }

  getImageDimensions(url: string): Promise<{ width: number, height: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }

}
