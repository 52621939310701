<h1 mat-dialog-title>Přesunou stránky<strong></strong></h1>
<div mat-dialog-content>

  <div class="mt-6 flex flex-col flex-wrap items-center justify-center" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <form class="flex-auto" [formGroup]="form" *ngIf="!loading">
    <h2><span>Přesun stránku: </span><strong>{{pageItem.name}}</strong><span> za:</span></h2>
    <mat-form-field class="flex flex-col">
    <mat-label>Stránku</mat-label>
    <mat-select formControlName="pageItemId">
      <mat-option *ngFor="let item of this.pages" [value]="item.id" [disabled]="pageItem.id === item.id">{{item.name}}</mat-option>
    </mat-select>
    <mat-icon class="mr-3" matPrefix svgIcon="mat:menu_open"></mat-icon>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions cdkTrapFocus class="flex flex-row items-center">
  <button mat-flat-button color="primary" class="m-5" (click)="dialogRef.close(false)" cdkFocusInitial>Zrušit</button>
  <button mat-flat-button color="accent"  class="m-5" (click)="save()" [disabled]="(form.value.pageItemId === null) ? true : false">Přesunout</button>
</div>
