/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NewsLangExcludingLangsWithRelations } from './newsLangExcludingLangsWithRelations';
import { NewsAttachmentExcludingLangsWithRelations } from './newsAttachmentExcludingLangsWithRelations';
import { ItemExcludingLangsWithRelations } from './itemExcludingLangsWithRelations';


/**
 * (tsType: Omit<NewsWithRelations, \'langs\'>, schemaOptions: { title: \'FrontendNews\', includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface FrontendNews { 
    id?: number;
    published: boolean;
    pinned?: boolean | null;
    publishDate?: string | null;
    unpublishDate?: string | null;
    created?: string;
    creator?: string;
    lang?: NewsLangExcludingLangsWithRelations;
    langs?: Array<NewsLangExcludingLangsWithRelations>;
    attachments?: Array<NewsAttachmentExcludingLangsWithRelations>;
    items?: Array<ItemExcludingLangsWithRelations>;
}

