/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TeamMemberWithRelations } from './teamMemberWithRelations';
import { BlogPostViewWithRelations } from './blogPostViewWithRelations';
import { BlogPostLangWithRelations } from './blogPostLangWithRelations';
import { BlogPostTagWithRelations } from './blogPostTagWithRelations';
import { BlogPost } from './blogPost';
import { BlogPostCategoryWithRelations } from './blogPostCategoryWithRelations';


/**
 * (tsType: BlogPostWithRelations, schemaOptions: { includeRelations: true })
 */
export interface BlogPostWithRelations { 
    id?: number;
    published: boolean;
    publishDate?: string | null;
    unpublishDate?: string | null;
    created?: string;
    creator?: string;
    cover?: string | null;
    viewsCount?: number;
    lastViewDate?: string | null;
    blogPostCategoryId?: number;
    relatedBlogPosts?: Array<BlogPost>;
    langs?: Array<BlogPostLangWithRelations>;
    lang?: BlogPostLangWithRelations;
    authors?: Array<TeamMemberWithRelations>;
    category?: BlogPostCategoryWithRelations;
    tags?: Array<BlogPostTagWithRelations>;
    views?: Array<BlogPostViewWithRelations>;
}

