/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BlogPostTagExcludingCreatorCreatedLangWithRelations } from './blogPostTagExcludingCreatorCreatedLangWithRelations';
import { TeamMemberExcludingCreatorCreatedLangWithRelations } from './teamMemberExcludingCreatorCreatedLangWithRelations';
import { BlogPostCategoryExcludingCreatorCreatedLangWithRelations } from './blogPostCategoryExcludingCreatorCreatedLangWithRelations';
import { BlogPost } from './blogPost';
import { BlogPostLangExcludingCreatorCreatedLangWithRelations } from './blogPostLangExcludingCreatorCreatedLangWithRelations';
import { BlogPostViewExcludingCreatorCreatedLangWithRelations } from './blogPostViewExcludingCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<BlogPostWithRelations, \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'creator\', \'created\', \'lang\' ] })
 */
export interface BlogPostExcludingCreatorCreatedLangWithRelations { 
    id?: number;
    published: boolean;
    publishDate?: string | null;
    unpublishDate?: string | null;
    cover?: string | null;
    viewsCount?: number;
    lastViewDate?: string | null;
    blogPostCategoryId?: number;
    relatedBlogPosts?: Array<BlogPost>;
    langs?: Array<BlogPostLangExcludingCreatorCreatedLangWithRelations>;
    lang?: BlogPostLangExcludingCreatorCreatedLangWithRelations;
    authors?: Array<TeamMemberExcludingCreatorCreatedLangWithRelations>;
    category?: BlogPostCategoryExcludingCreatorCreatedLangWithRelations;
    tags?: Array<BlogPostTagExcludingCreatorCreatedLangWithRelations>;
    views?: Array<BlogPostViewExcludingCreatorCreatedLangWithRelations>;
}

