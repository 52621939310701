import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService} from './../services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private snackBar: MatSnackBar, private authenticationService: AuthenticationService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {

          // auto logout if 401 response returned from api
            if (err.status === 401 || err.status === 403) {
              console.log(err);
              this.authenticationService.logout();
              this.router.navigate(['/login']);
            } else{
                if(err.status === 422) {
                  // DUPLICATE ENTRY
                }else{
                  console.log(err);
                  this.snackBar.open("Chyba serveru", "OK", {duration: 3000});
                }
            }
            return throwError(err);
        }));

    }
}
