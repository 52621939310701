/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemExcludingIdWithRelations } from './itemExcludingIdWithRelations';
import { AuthorLangExcludingIdWithRelations } from './authorLangExcludingIdWithRelations';


/**
 * (tsType: Omit<AuthorWithRelations, \'id\'>, schemaOptions: { exclude: [ \'id\' ], includeRelations: true })
 */
export interface AuthorExcludingIdWithRelations { 
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string;
    birthday?: string | null;
    death?: string | null;
    canvasId?: number | null;
    created?: string;
    lang?: AuthorLangExcludingIdWithRelations;
    items?: Array<ItemExcludingIdWithRelations>;
    langs?: Array<AuthorLangExcludingIdWithRelations>;
}

