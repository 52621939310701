/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NewsAttachmentWithRelations } from './newsAttachmentWithRelations';
import { ItemWithRelations } from './itemWithRelations';
import { NewsLangWithRelations } from './newsLangWithRelations';


/**
 * (tsType: NewsWithRelations, schemaOptions: { includeRelations: true })
 */
export interface NewsWithRelations { 
    id?: number;
    published: boolean;
    pinned?: boolean | null;
    publishDate?: string | null;
    unpublishDate?: string | null;
    created?: string;
    creator?: string;
    lang?: NewsLangWithRelations;
    langs?: Array<NewsLangWithRelations>;
    attachments?: Array<NewsAttachmentWithRelations>;
    items?: Array<ItemWithRelations>;
}

