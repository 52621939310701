/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<BlogPostViewWithRelations, \'langs\'>, schemaOptions: { includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface BlogPostViewExcludingLangsWithRelations { 
    id?: string;
    date: string;
    langId: number;
    blogPostId?: number;
}

