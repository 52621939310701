import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'kodl-json-modal',
  templateUrl: './json-modal.component.html',
  styleUrls: ['./json-modal.component.scss']
})
export class JsonModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public jsonData: any,private dialogRef: MatDialogRef<JsonModalComponent>) {
  }
  ngOnInit() {
  }
}
