/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface Author { 
    id?: number;
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string;
    birthday?: string | null;
    death?: string | null;
    canvasId?: number | null;
    created?: string;
}

