/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: PageBlockLangWithRelations, schemaOptions: { includeRelations: true })
 */
export interface PageBlockLangWithRelations { 
    id?: number;
    pageBlockId?: number;
    langId: number;
    title?: string | null;
    content?: string | null;
}

