<form>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">JSON</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="text-border"></mat-divider>

  <mat-dialog-content class="flex flex-col">
   <pre>
     {{jsonData | json}}
   </pre>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button color="primary" mat-dialog-close>OK</button>
  </mat-dialog-actions>
</form>
