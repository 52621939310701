/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<NewPage, \'id\'>, schemaOptions: { title: \'NewPage\', exclude: [ \'id\' ] })
 */
export interface NewPage { 
    name: string;
    slug: string;
    pageType?: number;
    robots?: string;
    visible?: boolean;
    systemPage?: boolean | null;
    renderToSitemap?: boolean;
    lft?: number;
    rgt?: number;
    parentId?: number | null;
}

