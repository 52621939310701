/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionLangPartialExcludingAuctionIdWithRelations } from './auctionLangPartialExcludingAuctionIdWithRelations';
import { PressReleasePartialExcludingAuctionIdWithRelations } from './pressReleasePartialExcludingAuctionIdWithRelations';
import { AuctionFilePartialExcludingAuctionIdWithRelations } from './auctionFilePartialExcludingAuctionIdWithRelations';
import { AuctionStatusPartialExcludingAuctionIdWithRelations } from './auctionStatusPartialExcludingAuctionIdWithRelations';
import { ExhibitionPartialExcludingAuctionIdWithRelations } from './exhibitionPartialExcludingAuctionIdWithRelations';
import { ItemPartialExcludingAuctionIdWithRelations } from './itemPartialExcludingAuctionIdWithRelations';


/**
 * (tsType: Omit<Partial<AuctionWithRelations>, \'auctionId\'>, schemaOptions: { includeRelations: true, exclude: [ \'auctionId\' ], partial: true })
 */
export interface AuctionPartialExcludingAuctionIdWithRelations { 
    id?: number;
    auctionNumber?: string | null;
    internetAuction?: boolean;
    fee?: number | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    youtubeVideoId?: string | null;
    mainAuction?: boolean;
    archived?: boolean;
    canvasId?: number | null;
    artslimitId?: number | null;
    exhibitionId?: number | null;
    canOrderCatalog?: boolean | null;
    visible?: boolean;
    created?: string;
    status?: number;
    auctionStatus?: AuctionStatusPartialExcludingAuctionIdWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionPartialExcludingAuctionIdWithRelations;
    files?: Array<AuctionFilePartialExcludingAuctionIdWithRelations>;
    lang?: AuctionLangPartialExcludingAuctionIdWithRelations;
    langs?: Array<AuctionLangPartialExcludingAuctionIdWithRelations>;
    items?: Array<ItemPartialExcludingAuctionIdWithRelations>;
    pressReleases?: Array<PressReleasePartialExcludingAuctionIdWithRelations>;
}

