/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PressReleaseLangExcludingIdCreatorCreatedLangWithRelations } from './pressReleaseLangExcludingIdCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<PressReleaseWithRelations, \'id\' | \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'creator\', \'created\', \'lang\' ] })
 */
export interface PressReleaseExcludingIdCreatorCreatedLangWithRelations { 
    auctionId?: number | null;
    exhibitionId?: number | null;
    releaseDate: string;
    visible?: boolean;
    lang?: PressReleaseLangExcludingIdCreatorCreatedLangWithRelations;
    langs?: Array<PressReleaseLangExcludingIdCreatorCreatedLangWithRelations>;
}

