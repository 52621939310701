/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemStatusPartialExcludingAuctionIdWithRelations } from './itemStatusPartialExcludingAuctionIdWithRelations';
import { ItemPicturePartialExcludingAuctionIdWithRelations } from './itemPicturePartialExcludingAuctionIdWithRelations';
import { AuthorPartialExcludingAuctionIdWithRelations } from './authorPartialExcludingAuctionIdWithRelations';
import { AuctionPartialExcludingAuctionIdWithRelations } from './auctionPartialExcludingAuctionIdWithRelations';
import { AuthorRecordsPartialExcludingAuctionIdWithRelations } from './authorRecordsPartialExcludingAuctionIdWithRelations';
import { ExhibitionPartialExcludingAuctionIdWithRelations } from './exhibitionPartialExcludingAuctionIdWithRelations';
import { ItemLangPartialExcludingAuctionIdWithRelations } from './itemLangPartialExcludingAuctionIdWithRelations';


/**
 * (tsType: Omit<Partial<ItemWithRelations>, \'auctionId\'>, schemaOptions: { includeRelations: true, exclude: [ \'auctionId\' ], partial: true })
 */
export interface ItemPartialExcludingAuctionIdWithRelations { 
    id?: number;
    exhibitionId?: number;
    size?: string | null;
    priceEstimationLow?: number | null;
    priceEstimationHigh?: number | null;
    priceCurrent?: number | null;
    priceHammer?: number | null;
    priceFinal?: number | null;
    startPriceForBidding?: number | null;
    startPriceForClosing?: number | null;
    catalogueNumber?: number | null;
    canvasId?: number | null;
    artslimitId?: number | null;
    created?: string;
    lastModified?: string;
    status?: number;
    auction?: AuctionPartialExcludingAuctionIdWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionPartialExcludingAuctionIdWithRelations;
    lang?: ItemLangPartialExcludingAuctionIdWithRelations;
    langs?: Array<ItemLangPartialExcludingAuctionIdWithRelations>;
    pictures?: Array<ItemPicturePartialExcludingAuctionIdWithRelations>;
    authors?: Array<AuthorPartialExcludingAuctionIdWithRelations>;
    itemStatus?: ItemStatusPartialExcludingAuctionIdWithRelations;
    record?: AuthorRecordsPartialExcludingAuctionIdWithRelations;
}

