/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserScopeFilterFields } from './userScopeFilterFields';
import { UserScopeFilterOrder } from './userScopeFilterOrder';


export interface UserScopeFilter { 
    offset?: number;
    limit?: number;
    skip?: number;
    order?: UserScopeFilterOrder;
    where?: { [key: string]: any; };
    fields?: UserScopeFilterFields;
    include?: Array<{ [key: string]: any; }>;
}

