/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<Reference, \'id\'>, schemaOptions: { title: \'newReference\', exclude: [ \'id\' ] })
 */
export interface NewReference { 
    langId?: number;
    link: string;
    filename?: string | null;
    pagePreview?: string | null;
    title: string;
    description?: string;
    serverName?: string;
    visible?: boolean;
    created?: string;
    order?: number;
}

