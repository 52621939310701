/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ExhibitionExcludingIdVisibleStatusWithRelations } from './exhibitionExcludingIdVisibleStatusWithRelations';
import { ItemPictureExcludingIdVisibleStatusWithRelations } from './itemPictureExcludingIdVisibleStatusWithRelations';
import { ItemLangExcludingIdVisibleStatusWithRelations } from './itemLangExcludingIdVisibleStatusWithRelations';
import { AuctionExcludingIdVisibleStatusWithRelations } from './auctionExcludingIdVisibleStatusWithRelations';
import { AuthorExcludingIdVisibleStatusWithRelations } from './authorExcludingIdVisibleStatusWithRelations';
import { ItemStatusExcludingIdVisibleStatusWithRelations } from './itemStatusExcludingIdVisibleStatusWithRelations';
import { AuthorRecordsExcludingIdVisibleStatusWithRelations } from './authorRecordsExcludingIdVisibleStatusWithRelations';


/**
 * (tsType: Omit<ItemWithRelations, \'id\' | \'visible\' | \'status\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'visible\', \'status\' ] })
 */
export interface ItemExcludingIdVisibleStatusWithRelations { 
    auctionId?: number;
    exhibitionId?: number;
    size?: string | null;
    priceEstimationLow?: number | null;
    priceEstimationHigh?: number | null;
    priceCurrent?: number | null;
    priceHammer?: number | null;
    priceFinal?: number | null;
    startPriceForBidding?: number | null;
    startPriceForClosing?: number | null;
    catalogueNumber?: number | null;
    canvasId?: number | null;
    artslimitId?: number | null;
    created?: string;
    lastModified?: string;
    auction?: AuctionExcludingIdVisibleStatusWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingIdVisibleStatusWithRelations;
    lang?: ItemLangExcludingIdVisibleStatusWithRelations;
    langs?: Array<ItemLangExcludingIdVisibleStatusWithRelations>;
    pictures?: Array<ItemPictureExcludingIdVisibleStatusWithRelations>;
    authors?: Array<AuthorExcludingIdVisibleStatusWithRelations>;
    itemStatus?: ItemStatusExcludingIdVisibleStatusWithRelations;
    record?: AuthorRecordsExcludingIdVisibleStatusWithRelations;
}

