<div (mouseenter)="collapseOpenSidenav()"
     (mouseleave)="collapseCloseSidenav()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="vex-sidenav-toolbar__container flex flex-col">
    <div class="sidenav-toolbar flex flex-col justify-center items-center mb-2 -mt-0.5">
      <img [src]="imageDarkUrl$ | async" alt="Kodl logo" class="text-center w-24" width="890" height="520" />
    </div>

    <div *ngIf="searchVisible$ | async" class="vex-sidenav-search__container">
      <div class="vex-sidenav-search relative"
           (click)="openSearch()"
           matRipple
           matRippleColor="var(--sidenav-item-ripple-color)">
        <mat-icon class="flex-none vex-sidenav-search__icon" svgIcon="mat:search"></mat-icon>
        <div class="flex-auto vex-sidenav-search__placeholder">Rychlé vyhledávání</div>
        <div class="flex-none vex-sidenav-search__keybind">CTRL+K</div>
      </div>
    </div>

    <div class="vex-sidenav-toolbar__divider"></div>
  </div>

  <vex-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute" [item]="item" [level]="0"></vex-sidenav-item>
    </div>

    <div class="vex-sidenav-footer flex items-center justify-center opacity-20 px-6 py-6 select-none flex-none">
      <img [src]="imageDarkUrl$ | async" alt="Logo" class="w-8 select-none"/>
    </div>
  </vex-scrollbar>

  <div *ngIf="false"
       class="vex-sidenav-user__container flex-none">
    <div #userProfileMenuOriginRef
         (click)="openProfileMenu(userProfileMenuOriginRef)"
         [class.vex-sidenav-user--open]="userMenuOpen$ | async"
         class="vex-sidenav-user flex items-center"
         matRipple
         matRippleColor="var(--sidenav-item-ripple-color)">
      <img alt="User Avatar" class="vex-sidenav-user__image flex-none" src="assets/img/avatars/1.jpg"/>
      <div class="vex-sidenav-user__content flex-auto">
        <div class="vex-sidenav-user__title">David Smith</div>
        <div class="vex-sidenav-user__subtitle">Product Manager</div>
      </div>
      <mat-icon class="vex-sidenav-user__dropdown-icon flex-none" svgIcon="mat:arrow_drop_down"></mat-icon>
    </div>
  </div>
</div>
