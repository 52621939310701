/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BlogPostExcludingIdWithRelations } from './blogPostExcludingIdWithRelations';
import { BlogPostTagLangExcludingIdWithRelations } from './blogPostTagLangExcludingIdWithRelations';


/**
 * (tsType: Omit<BlogPostTagWithRelations, \'id\'>, schemaOptions: { title: \'NewBlogPostTag\', includeRelations: true, exclude: [ \'id\' ] })
 */
export interface NewBlogPostTag { 
    langs?: Array<BlogPostTagLangExcludingIdWithRelations>;
    lang?: BlogPostTagLangExcludingIdWithRelations;
    posts?: Array<BlogPostExcludingIdWithRelations>;
}

