import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LangsService} from '../../services/langs.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {
  AdminService, AuctionFileLang,
  AuctionFileLangWithRelations,
  AuctionFileWithRelations,
  LangWithRelations
} from "../../../../@backend";
import {FileUpload} from 'primeng/fileupload';
import {auctionFileTypes} from "../../models/auctionFileTypes";

@Component({
  selector: 'app-add-auction-file',
  templateUrl: './add-auction-file.component.html'
})
export class AddAuctionFileComponent {

  public langs: LangWithRelations[];
  public auctionFile: AuctionFileWithRelations;
  atleastOneFileUploaded: boolean = false;
  auctionId: number;
  fileBlobs: Blob[];
  filesTypeCs: any = Object.keys(auctionFileTypes);

  uploading: boolean = false;

  form = this.fb.group({
    type: [null, Validators.required]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddAuctionFileComponent>,
    public langService: LangsService,
    private fb: UntypedFormBuilder,
    private adminService: AdminService,
  ) {
    console.log(this.filesTypeCs);
    if(data?.auctionId) {
      this.auctionId = data.auctionId;
    }else{
      console.log('Missing auction ID');
    }
    this.langs = this.langService.getLangs();
    this.auctionFile = {} as AuctionFileWithRelations;
    this.auctionFile.auctionId = this.auctionId;
    this.auctionFile.langs = [] as AuctionFileLangWithRelations[];
    for (const lang of this.langs) {
      this.auctionFile.langs.push({langId: lang.id} as AuctionFileLangWithRelations);
    }

  }

  valid() {
    if(this.form.valid && this.atleastOneFileUploaded) {
      return true;
    }
  }

  async save() {
    if(this.valid()){
      this.uploading = true;
      this.auctionFile.type = this.form.value.type;
      const {langs, ...tmpAuctionFile} = this.auctionFile;
      try {
        const dbAuctionFile = await this.adminService.auctionControllerAdminCreateAuctionFile(tmpAuctionFile).toPromise();
        let lastUploaded: {filename: string};

        for (const lang of langs) {
          if(lang?.filename) { // If some file selected for language
            // lang.filename is originally string, but for time saving I used to save blob for now.
            // @ts-ignore
            lastUploaded = await this.adminService.auctionControllerUploadAuctionFile(dbAuctionFile.id, lang.langId, lang.filename).toPromise();
          }else{ // Lets fake file, no other language provided
            await this.adminService.auctionControllerUploadAuctionFakeFile({fileId: dbAuctionFile.id, langId: lang.langId, filename: lastUploaded.filename} as AuctionFileLang).toPromise();
          }
        }
        this.uploading = false;
        this.dialogRef.close(true);

      }catch (e) {
        this.uploading = false;
        if(e?.error.error.code === "ER_DUP_ENTRY") {
          this.form.controls.type.setErrors({'exist': true});
        }
      }

    }
  }

  async processUpload(langId, event: any, uploadButton: FileUpload) {
    for(let file of event.files) {
      this.atleastOneFileUploaded = true;
      this.auctionFile.langs[langId-1].filename = file;
    }
    uploadButton.clear();
  }

  protected readonly auctionFileTypes = auctionFileTypes;
}
