import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AdminService} from "../../../../@backend";
import {MatSnackBar} from '@angular/material/snack-bar';
import {LangsService} from '../../services/langs.service';
import {FileUpload} from 'primeng/fileupload';
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'kodl-image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.scss']
})
export class ImageUploadModalComponent implements OnInit {
  loading: boolean = true;
  mode: 'add' | 'change' = 'add';
  type: string = 'image';

  file: any;
  fileUrl: string;

  form: FormGroup = this.fb.group({
    // name: ['', Validators.required],
    // code: ['', Validators.required]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<ImageUploadModalComponent>,
              private fb: FormBuilder,
              private snackBar: MatSnackBar,
              public langsService: LangsService,
              private adminService: AdminService,) {
    this.init();
  }

  async init(): Promise<void>{

    this.loading = true;
    if(this.mode === 'add') {

    }else if(this.mode === 'change'){

    }
    this.loading = false;
  }

  ngOnInit() {
      console.log('ImageModel opened');
  }


  async save(): Promise<void> {

    if(this.form.valid){

      if(this.mode === 'add') {
        try {
          const result = await firstValueFrom(this.adminService.assetManagerControllerAdminUploadAsset(this.file));
          if(result?.filename && result?.url) {
            this.snackBar.open("Uspěšně uloženo", "OK", {duration: 2000});
            this.dialogRef.close(result);
          }
        }catch (e) {
          this.snackBar.open("Soubor se nepodařilo nahrát", "OK", {duration: 2000, panelClass: ['bg-red-600']});
        }

      }else {
        try {
          this.dialogRef.close(true);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }


  processUpload(event: any, button: FileUpload) {
    for(let file of event.files) {
      this.file = file;
    }
    button.clear();
    this.showPreviewOfImage();
  }

  showPreviewOfImage() {
    const reader = new FileReader();
    reader.onload = () => {
      this.fileUrl = reader.result as string;
    }
    reader.readAsDataURL(this.file);
  }


}
