import { Pipe, PipeTransform } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ImgControllerService} from "../../../@backend";
import {AuthenticationService} from '../services/authentication.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'photoProxy'
})
export class PhotoProxyPipe implements PipeTransform {


  webCdnUrl: string = environment.webCdnUrl;
  sharedCdnUrl: string = environment.sharedCdnUrl;
  constructor() {
  }
  async transform(filename: string, modifiers: string = "rs:fit:240:200:1/g:no", path: string = 'slider', bucket: string = 'web'): Promise<SafeHtml> {
    let imageSrc;
    if(filename){
        if(bucket === 'shared') {
          path = 's3://' + bucket + '.prague/' + path + '/' + filename;
          imageSrc = this.sharedCdnUrl + '/plain/' + modifiers + "/" + btoa(path) + ".webp";
        }else{
          path = "s3://web.prague/" + path + "/" + filename;
          imageSrc = this.webCdnUrl + '/plain/' + modifiers + "/" + btoa(path) + ".webp";
        }
    }
    return imageSrc;
    }

}
