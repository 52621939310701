import { Injectable } from '@angular/core';
import {LangControllerService, LangWithRelations} from "../../../@backend";
import {firstValueFrom} from "rxjs";

@Injectable({ providedIn: 'root' })
export class LangsService {

  // public langs: string[] = ['', 'CZ', 'EN'];
  public langs: LangWithRelations[];

  constructor(private langsHttpService:LangControllerService) {
    this.init();
  }

  async init() {
    this.langs = await firstValueFrom(this.langsHttpService.langControllerGetAvailableLangs());
  }
  getLangs(): LangWithRelations[] {
    return this.langs;
  }

  getLangsShortCode(index: number): string {
    index = index - 1;
    return this.langs[index].shortCode.toUpperCase();
  }

}
