/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorRecordsExcludingIdCreatorCreatedLangWithRelations } from './authorRecordsExcludingIdCreatorCreatedLangWithRelations';
import { AuthorExcludingIdCreatorCreatedLangWithRelations } from './authorExcludingIdCreatorCreatedLangWithRelations';
import { ItemPictureExcludingIdCreatorCreatedLangWithRelations } from './itemPictureExcludingIdCreatorCreatedLangWithRelations';
import { ItemLangExcludingIdCreatorCreatedLangWithRelations } from './itemLangExcludingIdCreatorCreatedLangWithRelations';
import { AuctionExcludingIdCreatorCreatedLangWithRelations } from './auctionExcludingIdCreatorCreatedLangWithRelations';
import { ExhibitionExcludingIdCreatorCreatedLangWithRelations } from './exhibitionExcludingIdCreatorCreatedLangWithRelations';
import { ItemStatusExcludingIdCreatorCreatedLangWithRelations } from './itemStatusExcludingIdCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<ItemWithRelations, \'id\' | \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'creator\', \'created\', \'lang\' ] })
 */
export interface ItemExcludingIdCreatorCreatedLangWithRelations { 
    auctionId?: number;
    exhibitionId?: number;
    size?: string | null;
    priceEstimationLow?: number | null;
    priceEstimationHigh?: number | null;
    priceCurrent?: number | null;
    priceHammer?: number | null;
    priceFinal?: number | null;
    startPriceForBidding?: number | null;
    startPriceForClosing?: number | null;
    catalogueNumber?: number | null;
    canvasId?: number | null;
    artslimitId?: number | null;
    lastModified?: string;
    status?: number;
    auction?: AuctionExcludingIdCreatorCreatedLangWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingIdCreatorCreatedLangWithRelations;
    lang?: ItemLangExcludingIdCreatorCreatedLangWithRelations;
    langs?: Array<ItemLangExcludingIdCreatorCreatedLangWithRelations>;
    pictures?: Array<ItemPictureExcludingIdCreatorCreatedLangWithRelations>;
    authors?: Array<AuthorExcludingIdCreatorCreatedLangWithRelations>;
    itemStatus?: ItemStatusExcludingIdCreatorCreatedLangWithRelations;
    record?: AuthorRecordsExcludingIdCreatorCreatedLangWithRelations;
}

