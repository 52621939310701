/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorLangExcludingIdVisibleStatusWithRelations } from './authorLangExcludingIdVisibleStatusWithRelations';
import { ItemExcludingIdVisibleStatusWithRelations } from './itemExcludingIdVisibleStatusWithRelations';


/**
 * (tsType: Omit<AuthorWithRelations, \'id\' | \'visible\' | \'status\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'visible\', \'status\' ] })
 */
export interface AuthorExcludingIdVisibleStatusWithRelations { 
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string;
    birthday?: string | null;
    death?: string | null;
    canvasId?: number | null;
    created?: string;
    lang?: AuthorLangExcludingIdVisibleStatusWithRelations;
    items?: Array<ItemExcludingIdVisibleStatusWithRelations>;
    langs?: Array<AuthorLangExcludingIdVisibleStatusWithRelations>;
}

