<h1 mat-dialog-title>Nahrání souboru k novince</h1>
<div mat-dialog-content>
  <div class="flex flex-col items-center m-2">
    <div>
      <p-fileUpload
        #newsFileUpload
        mode="basic"
        [customUpload]="true"
        (uploadHandler)="processUpload($event, newsFileUpload)"
        [name]="'newsfile'"
        [auto]="true"
        accept="image/*,application/pdf"
        chooseLabel="Nahrát soubor">
      </p-fileUpload>
    </div>
  </div>

  <form class="flex-auto m-2" [formGroup]="form">
    <mat-form-field class="flex flex-col">
      <mat-label>Typ souboru</mat-label>
      <mat-select formControlName="type" placeholder="Vyberte typ souboru">
        <mat-option value="catalogue">Aukční katalog</mat-option>
        <mat-option value="limittable">Limitní tabulka</mat-option>
        <mat-option value="poatel">Plná moc (telefon)</mat-option>
        <mat-option value="poasite">Plná moc (pevná)</mat-option>
        <mat-option value="frontpage">Úvodní strana</mat-option>
        <mat-option value="returnlist">Výsledková listina</mat-option>
        <mat-option value="other">Jiný</mat-option>
      </mat-select>
      <mat-icon class="mr-3" matPrefix svgIcon="mat:file_present"></mat-icon>
      <mat-error *ngIf="form.controls.type.hasError('exist')">Tato typ souboru již byl nahrán. Upravit soubor lze na detailu aukce.</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="flex w-full justify-center">
  <button mat-flat-button color="accent"  class="m-2" (click)="save()" [disabled]="!valid() || uploading">Přidat</button>
  <button mat-flat-button color="primary" class="m-2" (click)="dialogRef.close(false)" cdkFocusInitial>Zrušit</button>
</div>
