/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorLangExcludingLangsWithRelations } from './authorLangExcludingLangsWithRelations';
import { ItemExcludingLangsWithRelations } from './itemExcludingLangsWithRelations';


/**
 * (tsType: Omit<AuthorWithRelations, \'langs\'>, schemaOptions: { includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface AuthorExcludingLangsWithRelations { 
    id?: number;
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string;
    birthday?: string | null;
    death?: string | null;
    canvasId?: number | null;
    created?: string;
    lang?: AuthorLangExcludingLangsWithRelations;
    items?: Array<ItemExcludingLangsWithRelations>;
    langs?: Array<AuthorLangExcludingLangsWithRelations>;
}

