import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class StringService {
    constructor() {
    }

  createPrettyUrl(value: string): string {
    value = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    const url = value == undefined ? '' : value.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
    return url;
  }

}
