/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BlogPostWithRelations } from './blogPostWithRelations';
import { BlogPostCategoryLangWithRelations } from './blogPostCategoryLangWithRelations';


/**
 * (tsType: BlogPostCategoryWithRelations, schemaOptions: { includeRelations: true })
 */
export interface BlogPostCategoryWithRelations { 
    id?: number;
    blogPostId?: number;
    langs?: Array<BlogPostCategoryLangWithRelations>;
    lang?: BlogPostCategoryLangWithRelations;
    posts?: Array<BlogPostWithRelations>;
}

