/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PressReleaseExcludingLangsWithRelations } from './pressReleaseExcludingLangsWithRelations';
import { AuctionExcludingLangsWithRelations } from './auctionExcludingLangsWithRelations';
import { ItemExcludingLangsWithRelations } from './itemExcludingLangsWithRelations';
import { ExhibitionLangExcludingLangsWithRelations } from './exhibitionLangExcludingLangsWithRelations';


/**
 * (tsType: Omit<ExhibitionWithRelations, \'langs\'>, schemaOptions: { title: \'FrontendExhibition\', includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface FrontendExhibition { 
    id?: number;
    auctionId?: number;
    exhibitionFrom: string;
    exhibitionTo: string;
    mainExhibition?: boolean;
    matterportId?: string | null;
    youtubeVideoId?: string | null;
    archived?: boolean;
    status?: number;
    visible?: boolean;
    showPrices?: boolean | null;
    created?: string;
    lastModified?: string | null;
    auction?: AuctionExcludingLangsWithRelations;
    foreignKey?: any | null;
    lang?: ExhibitionLangExcludingLangsWithRelations;
    langs?: Array<ExhibitionLangExcludingLangsWithRelations>;
    items?: Array<ItemExcludingLangsWithRelations>;
    pressReleases?: Array<PressReleaseExcludingLangsWithRelations>;
}

