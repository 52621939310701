/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface FormCatalogOrder { 
    id?: string;
    auctionId?: number;
    name: string;
    email: string;
    telephone: string;
    address?: string | null;
    deliveryType: FormCatalogOrder.DeliveryTypeEnum;
    created?: string;
    langId?: number;
    status?: FormCatalogOrder.StatusEnum | null;
}
export namespace FormCatalogOrder {
    export type DeliveryTypeEnum = 'pickup' | 'post';
    export const DeliveryTypeEnum = {
        Pickup: 'pickup' as DeliveryTypeEnum,
        Post: 'post' as DeliveryTypeEnum
    };
    export type StatusEnum = 'accepted' | 'inProgress' | 'sent';
    export const StatusEnum = {
        Accepted: 'accepted' as StatusEnum,
        InProgress: 'inProgress' as StatusEnum,
        Sent: 'sent' as StatusEnum
    };
}


