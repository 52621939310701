/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: BlogPostTagLangWithRelations, schemaOptions: { includeRelations: true })
 */
export interface BlogPostTagLangWithRelations { 
    id?: number;
    langId?: number;
    name: string;
    blogPostTagId?: number;
}

