/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FormItemOfferFileWithRelations } from './formItemOfferFileWithRelations';


/**
 * (tsType: FormItemOfferWithRelations, schemaOptions: { includeRelations: true })
 */
export interface FormItemOfferWithRelations { 
    id?: string;
    name: string;
    email: string;
    telephone: string;
    itemAuthor: string;
    itemTechnique?: string | null;
    itemSize?: string;
    itemDescription?: string | null;
    acquisitionType: FormItemOfferWithRelations.AcquisitionTypeEnum;
    acquisitionOther?: string | null;
    created?: string;
    langId?: number;
    isProcessing?: boolean | null;
    status: FormItemOfferWithRelations.StatusEnum;
    files?: Array<FormItemOfferFileWithRelations>;
}
export namespace FormItemOfferWithRelations {
    export type AcquisitionTypeEnum = 'heritage' | 'purchase' | 'restitution' | 'other';
    export const AcquisitionTypeEnum = {
        Heritage: 'heritage' as AcquisitionTypeEnum,
        Purchase: 'purchase' as AcquisitionTypeEnum,
        Restitution: 'restitution' as AcquisitionTypeEnum,
        Other: 'other' as AcquisitionTypeEnum
    };
    export type StatusEnum = 'new' | 'inProgress' | 'done' | 'closed';
    export const StatusEnum = {
        New: 'new' as StatusEnum,
        InProgress: 'inProgress' as StatusEnum,
        Done: 'done' as StatusEnum,
        Closed: 'closed' as StatusEnum
    };
}


