/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PageBlockLang } from './pageBlockLang';


/**
 * (tsType: Omit<AdminPageBlock, \'id\'>, schemaOptions: { title: \'NewPageBlock\', exclude: [ \'id\' ] })
 */
export interface NewPageBlock { 
    pageId: number;
    name: string;
    code: string;
    type?: string;
    order?: number | null;
    content?: string | null;
    cssClass?: string | null;
    langs?: Array<PageBlockLang>;
}

