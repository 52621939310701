/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<BlogPostLangWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface BlogPostLangExcludingIdWithRelations { 
    langId?: number;
    blogPostId?: number;
    title?: string;
    slug?: string | null;
    content?: string | null;
    perex?: string | null;
    readingTime?: number | null;
    wordCount?: number | null;
}

