import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LangsService} from '../../services/langs.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {
  AdminService, AuctionFileLang,
  AuctionFileLangWithRelations,
  AuctionFileWithRelations,
  LangWithRelations
} from "../../../../@backend";
import {FileUpload} from 'primeng/fileupload';
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-add-news-file',
  templateUrl: './add-news-file.component.html',
  styleUrls: ['./add-news-file.component.scss']
})
export class AddNewsFileComponent {

  public langs: LangWithRelations[];
  public file: any;

  atleastOneFileUploaded: boolean = false;
  newsId: number;
  uploading: boolean = false;

  form = this.fb.group({
    type: [null, Validators.required]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddNewsFileComponent>,
    public langService: LangsService,
    private fb: UntypedFormBuilder,
    private adminService: AdminService,
  ) {
    if(data?.newsId) {
      this.newsId = data.newsId;
    }else{
      console.log('Missing news ID');
    }

  }

  valid() {
    if(this.form.valid && this.atleastOneFileUploaded) {
      return true;
    }
  }

  async save() {
    if(this.valid()){
      this.uploading = true;
      try {
        const newsAttachment = await firstValueFrom(this.adminService.newsControllerUploadNewsFile(this.newsId, this.form.value.type, this.file));
        this.uploading = false;
        this.dialogRef.close(newsAttachment);
      }catch (e) {
        this.uploading = false;
        if(e?.error.error.code === "ER_DUP_ENTRY") {
          this.form.controls.type.setErrors({'exist': true});
        }
      }

    }
  }

  async processUpload(event: any, uploadButton: FileUpload) {
    for(let file of event.files) {
      this.atleastOneFileUploaded = true;
      this.file = file;
    }
    uploadButton.clear();
  }
}
