<div class="m-3">
<div class="flex items-center" mat-dialog-title>
    <h2 *ngIf="isUpdateMode()" class="headline m-0 flex-auto">Úprava položky
      <mat-chip-listbox aria-label="Fish selection">
        <mat-chip selected matTooltip="Id">{{item.id}}</mat-chip>
        <mat-chip *ngIf="item?.canvasId" matTooltip="Canvas Id">
          <img matChipAvatar src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/C_Programming_Language.svg/695px-C_Programming_Language.svg.png" alt="Canvas ID"/>
          {{item.canvasId}}
        </mat-chip>
        <mat-chip *ngIf="item?.artslimitId" matTooltip="Artslimit Id">
          <img matChipAvatar src="https://www.artslimit.com/favicon-32x32.png" alt="artslimit"/>
          {{item.artslimitId}}</mat-chip>
      </mat-chip-listbox>
    </h2>
    <h2 *ngIf="isCreateMode()"
        class="headline m-0 flex-auto">Nová položka</h2>
<!--    <button [matMenuTriggerFor]="settingsMenu" class="text-secondary" mat-icon-button type="button">-->
<!--      <mat-icon svgIcon="mat:more_vert"></mat-icon>-->
<!--    </button>-->
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-dialog-content *ngIf="loading">
  <div class="mt-6 flex flex-col flex-wrap items-center justify-center">
    <mat-spinner></mat-spinner>
  </div>
  </mat-dialog-content>

  <mat-dialog-content *ngIf="!loading">
  <mat-divider class="text-border"></mat-divider>
  <div class="flex flex-row items-stretch gap-2">

<!--      md:w-full-->
    <div class="flex w-1/2">
      <mat-tab-group mat-stretch-tabs>
      <mat-tab *ngFor="let lang of item.langs">
        <ng-template mat-tab-label>
          <mat-icon svgIcon="flag:{{langsService.getLangsShortCode(lang.langId)}}"></mat-icon>
          <span class="pl-2">{{langsService.getLangsShortCode(lang.langId)}}</span>
        </ng-template>

        <ng-template matTabContent>
          <div class="pt-2 m-1 flex flex-col sm:flex-row sm:gap-2 overflow-hidden">

            <mat-form-field class="flex-auto" appearance="fill">
              <mat-label>Název</mat-label>
              <input matInput required [(ngModel)]="lang.name" [name]='"name_" + langsService.getLangsShortCode(lang.langId)'>
              <mat-icon class="mr-3" matPrefix svgIcon="mat:gavel"></mat-icon>
            </mat-form-field>

            <mat-form-field class="flex-auto" appearance="fill">
              <mat-label>Datace</mat-label>
              <input matInput required="true" [(ngModel)]="lang.dating" [name]='"dating_" + langsService.getLangsShortCode(lang.langId)'>
              <mat-icon class="mr-3" matPrefix svgIcon="mat:calendar_month"></mat-icon>
            </mat-form-field>

          </div>

          <div class="m-1 flex flex-col sm:flex-row sm:gap-2 overflow-hidden">
            <mat-form-field class="flex-auto" appearance="fill">
              <mat-label>Technika</mat-label>
              <input matInput required="true" [(ngModel)]="lang.technique" [name]='"technique_" + langsService.getLangsShortCode(lang.langId)'>
              <mat-icon class="mr-3" matPrefix svgIcon="mat:calendar_month"></mat-icon>
            </mat-form-field>

            <mat-form-field class="flex-auto" appearance="fill">
              <mat-label>Signatura</mat-label>
              <input matInput [(ngModel)]="lang.signature" [name]='"signature_" + langsService.getLangsShortCode(lang.langId)'>
              <mat-icon class="mr-3" matPrefix svgIcon="mat:drive_file_rename_outline"></mat-icon>
            </mat-form-field>
          </div>

          <div class="m-1 flex flex-col sm:flex-row sm:gap-2 overflow-hidden">
            <mat-form-field class="flex-auto" appearance="fill">
              <mat-label>Vlastnosti</mat-label>
              <input matInput [(ngModel)]="lang.properties" [name]='"properties_" + langsService.getLangsShortCode(lang.langId)'>
              <mat-icon class="mr-3" matPrefix svgIcon="mat:line_style"></mat-icon>
            </mat-form-field>
          </div>

          <div class="pt-1 flex flex-col items-stretch sm:flex-col sm:gap-1">
            <mat-label class="font-bold">Popis</mat-label>
            <editor class="w-full"
                    [(ngModel)]="lang.description"
                    [init]="tinyService.modulesEasy()">
            </editor>
          </div>
        </ng-template>

      </mat-tab>
    </mat-tab-group>
    </div>
    <div class="flex w-1/2">
        <mat-tab-group mat-stretch-tabs>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon svgIcon="mat:info"></mat-icon>
              <span class="pl-2">DETAIL POLOŽKY</span>
            </ng-template>

            <ng-template matTabContent>
              <div class="flex flex-col">
              <form (ngSubmit)="save()" [formGroup]="form">
                <div class="pt-2 flex flex-col sm:flex-row sm:gap-2">
                  <mat-form-field class="flex-auto" appearance="fill">
                    <mat-label>Katalogové číslo</mat-label>
                    <input matInput formControlName="catalogueNumber">
                    <mat-icon class="mr-2" matPrefix svgIcon="mat:numbers"></mat-icon>
                  </mat-form-field>

                  <mat-form-field class="flex-auto" appearance="fill">
                    <mat-label>Rozměry</mat-label>
                    <input matInput formControlName="size">
                    <mat-icon class="mr-2" matPrefix svgIcon="mat:aspect_ratio"></mat-icon>
                  </mat-form-field>
                </div>

                <div class="flex flex-col sm:flex-row sm:gap-1">
                  <mat-form-field class="flex-auto" appearance="fill">
                    <mat-label>Odhadovaná cena od</mat-label>
                    <input matInput formControlName="priceEstimationLow">
                    <!--            <mat-icon class="mr-2" matPrefix svgIcon="mat:numbers"></mat-icon>-->
                  </mat-form-field>

                  <mat-form-field class="flex-auto" appearance="fill">
                    <mat-label>Odhadovaná cena do</mat-label>
                    <input matInput formControlName="priceEstimationHigh">
                    <!--            <mat-icon class="mr-2" matPrefix svgIcon="mat:numbers"></mat-icon>-->
                  </mat-form-field>

                </div>

                <div class="flex flex-col sm:flex-row sm:gap-1">

                  <mat-form-field class="flex-auto" appearance="fill">
                    <mat-label>Vyvolávací cena</mat-label>
                    <input matInput formControlName="startPriceForBidding">
                  </mat-form-field>

                  <mat-form-field class="flex-auto" appearance="fill">
                    <mat-label>Kladívková cena</mat-label>
                    <input matInput formControlName="priceHammer">
                  </mat-form-field>

                  <mat-form-field class="flex-auto" appearance="fill">
                    <mat-label>Finální cena</mat-label>
                    <input matInput formControlName="priceFinal">
                  </mat-form-field>
                </div>



                <div class="flex flex-col sm:flex-row sm:gap-1">
                  <mat-form-field class="w-3/4" appearance="fill">
                    <mat-label>Autor</mat-label>
                    <mat-select multiple formControlName="authors" [compareWith]="isSelected">
                      <mat-option *ngFor="let a of authors" [value]="a" [attr.data-tag]="a.id">
                        <kodl-single-author [author]="a" [showDating]="true"></kodl-single-author>
                      </mat-option>
                    </mat-select>
                    <mat-icon class="mr-2" matPrefix svgIcon="mat:assignment_ind"></mat-icon>
                  </mat-form-field>


                  <mat-form-field class="w-1/4" appearance="fill">
                    <mat-label>Stav</mat-label>
                    <mat-select formControlName="status">
                      <mat-option *ngFor="let status of localItemService.statusesCz" [value]="status.index">
                        {{status.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

              </form>
              </div>
            </ng-template>

          </mat-tab>


          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon svgIcon="mat:photo"></mat-icon>
              <span class="pl-2">OBRÁZKY</span>
            </ng-template>

            <ng-template matTabContent>

              <div class="flex flex-col">
                <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                    <div *ngFor="let photo of item.pictures" style="z-index: 999;">
                      <img [src]="photo.filename + photo.ext | photoProxy:'rs:fit:340:300:1/g:no':'items':'shared' | async"
                           (click)="openPhotoPreview(photo.filename + photo.ext, true)"
                           class="h-auto max-w-full rounded-lg hover:scale-125 transition duration-500 cursor-pointer"
                           alt="">
                    </div>
                </div>
                <div class="mt-2 w-full">
                  <button mat-flat-button color="accent" class="w-full">Přidat fotky</button>
                </div>
              </div>
            </ng-template>

          </mat-tab>
        </mat-tab-group>
    </div>

  </div>
  </mat-dialog-content>


  <mat-dialog-actions align="end" *ngIf="!loading">
    <button mat-button mat-dialog-close type="button">Zavřít</button>
    <button *ngIf="isCreateMode()" color="primary" mat-flat-button (click)="save()" [disabled]="!isValid()">Vytvořit položku</button>
    <button *ngIf="isUpdateMode()" color="primary" mat-flat-button (click)="save()" [disabled]="!isValid()">Upravit položku</button>
  </mat-dialog-actions>

</div>
<!--<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">-->
<!--  <button mat-menu-item>-->
<!--    <mat-icon svgIcon="mat:print"></mat-icon>-->
<!--    <span>Print</span>-->
<!--  </button>-->

<!--  <button mat-menu-item>-->
<!--    <mat-icon svgIcon="mat:download"></mat-icon>-->
<!--    <span>Export</span>-->
<!--  </button>-->

<!--  <button mat-menu-item>-->
<!--    <mat-icon svgIcon="mat:delete"></mat-icon>-->
<!--    <span>Smazat</span>-->
<!--  </button>-->
<!--</mat-menu>-->
