import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'acquisition' })
export class AcquisitionTypePipe implements PipeTransform {
  private czStatus = {heritage: 'Dědictví', purchase: 'Koupě', restitution: 'Restituce', other: 'Jiná'};
  transform(value: string, langId = 1): string {
    if(this?.czStatus[value]) {
      return this.czStatus[value];
    }else{
      return value;
    }
  }
}
