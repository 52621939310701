/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<NewFormCatalogOrder, \'id\' | \'status\' | \'created\' | \'langId\'>, schemaOptions: { title: \'FrontendNewFormCatalogOrder\', exclude: [ \'id\', \'status\', \'created\', \'langId\' ] })
 */
export interface FrontendNewFormCatalogOrder { 
    auctionId?: number;
    name: string;
    email: string;
    telephone: string;
    address?: string | null;
    deliveryType: FrontendNewFormCatalogOrder.DeliveryTypeEnum;
    recaptchaToken: string;
}
export namespace FrontendNewFormCatalogOrder {
    export type DeliveryTypeEnum = 'pickup' | 'post';
    export const DeliveryTypeEnum = {
        Pickup: 'pickup' as DeliveryTypeEnum,
        Post: 'post' as DeliveryTypeEnum
    };
}


