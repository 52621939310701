/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BlogPostCategoryExcludingIdWithRelations } from './blogPostCategoryExcludingIdWithRelations';
import { BlogPostLangExcludingIdWithRelations } from './blogPostLangExcludingIdWithRelations';
import { BlogPostTagExcludingIdWithRelations } from './blogPostTagExcludingIdWithRelations';
import { BlogPostViewExcludingIdWithRelations } from './blogPostViewExcludingIdWithRelations';
import { BlogPost } from './blogPost';
import { TeamMemberExcludingIdWithRelations } from './teamMemberExcludingIdWithRelations';


/**
 * (tsType: Omit<BlogPostWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface BlogPostExcludingIdWithRelations { 
    published: boolean;
    publishDate?: string | null;
    unpublishDate?: string | null;
    created?: string;
    creator?: string;
    cover?: string | null;
    viewsCount?: number;
    lastViewDate?: string | null;
    blogPostCategoryId?: number;
    relatedBlogPosts?: Array<BlogPost>;
    langs?: Array<BlogPostLangExcludingIdWithRelations>;
    lang?: BlogPostLangExcludingIdWithRelations;
    authors?: Array<TeamMemberExcludingIdWithRelations>;
    category?: BlogPostCategoryExcludingIdWithRelations;
    tags?: Array<BlogPostTagExcludingIdWithRelations>;
    views?: Array<BlogPostViewExcludingIdWithRelations>;
}

