/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserScopeFilter } from './userScopeFilter';
import { UserIncludeFilterItems } from './userIncludeFilterItems';


export interface UserIncludeFilterInner { 
    relation?: UserIncludeFilterInner.RelationEnum;
    scope?: UserScopeFilter;
}
export namespace UserIncludeFilterInner {
    export type RelationEnum = 'userCredentials' | 'news';
    export const RelationEnum = {
        UserCredentials: 'userCredentials' as RelationEnum,
        News: 'news' as RelationEnum
    };
}


