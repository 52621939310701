/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BlogPostCategoryExcludingLangsWithRelations } from './blogPostCategoryExcludingLangsWithRelations';
import { BlogPostLangExcludingLangsWithRelations } from './blogPostLangExcludingLangsWithRelations';
import { TeamMemberExcludingLangsWithRelations } from './teamMemberExcludingLangsWithRelations';
import { BlogPost } from './blogPost';
import { BlogPostViewExcludingLangsWithRelations } from './blogPostViewExcludingLangsWithRelations';
import { BlogPostTagExcludingLangsWithRelations } from './blogPostTagExcludingLangsWithRelations';


/**
 * (tsType: Omit<BlogPostWithRelations, \'langs\'>, schemaOptions: { title: \'FrontendBlogPostsList\', includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface FrontendBlogPostsList { 
    id?: number;
    published: boolean;
    publishDate?: string | null;
    unpublishDate?: string | null;
    created?: string;
    creator?: string;
    cover?: string | null;
    viewsCount?: number;
    lastViewDate?: string | null;
    blogPostCategoryId?: number;
    relatedBlogPosts?: Array<BlogPost>;
    langs?: Array<BlogPostLangExcludingLangsWithRelations>;
    lang?: BlogPostLangExcludingLangsWithRelations;
    authors?: Array<TeamMemberExcludingLangsWithRelations>;
    category?: BlogPostCategoryExcludingLangsWithRelations;
    tags?: Array<BlogPostTagExcludingLangsWithRelations>;
    views?: Array<BlogPostViewExcludingLangsWithRelations>;
}

