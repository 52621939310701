/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface GalleryItem { 
    id?: number;
    galleryId: number;
    filename: string;
    fileType: string;
    isCover?: boolean;
    visible?: boolean;
    rank?: number;
}

