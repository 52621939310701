/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface BlogPost { 
    id?: number;
    published: boolean;
    publishDate?: string | null;
    unpublishDate?: string | null;
    created?: string;
    creator?: string;
    cover?: string | null;
    viewsCount?: number;
    lastViewDate?: string | null;
    blogPostCategoryId?: number;
    relatedBlogPosts?: Array<BlogPost>;
}

