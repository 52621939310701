/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: UpdateAuction, schemaOptions: { title: \'UpdateAuction\' })
 */
export interface UpdateAuction { 
    auctionId: number;
    canvasId: number;
    defaultItemStatus?: number;
}

