/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<AuthorLangWithRelations, \'langs\'>, schemaOptions: { includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface AuthorLangExcludingLangsWithRelations { 
    id?: number;
    authorId?: number | null;
    langId: number;
    description?: string | null;
}

