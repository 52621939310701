import { NgModule } from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {PhotoPipe} from '../pipes/photo.pipe';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {PhotoSecuredPipe} from '../pipes/photoSecured.pipe';
import {JsonModalComponent} from './json-modal/json-modal.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatDialogModule} from '@angular/material/dialog';
import {PhotoProxyPipe} from '../pipes/photoProxy.pipe';
import {ConfirmDialog} from './confirm-dialog/confirm-dialog.component';
import {AddLangDialogComponent} from './add-lang-dialog/add-lang-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TruncatePipe} from '../pipes/truncate.pipe';
import { AuthorComponent } from './author/author.component';
import {SingleAuthorComponent} from './single-author/single-author.component';
import {AddAuctionFileComponent} from './add-auction-file/add-auction-file.component';
import {MatTabsModule} from '@angular/material/tabs';
import {FileUploadModule} from 'primeng/fileupload';
import {ChangeMenuPositionComponent} from './change-menu-position/change-menu-position.component';
import {ChangePagePositionComponent} from './change-page-position/change-page-position.component';
import {ChangeSliderPositionComponent} from './change-slider-position/change-slider-position.component';
import {ImageModule} from 'primeng/image';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ImageUploadModalComponent} from './image-upload-modal/image-upload-modal.component';
import {StatusPipe} from '../pipes/status.pipe';
import {AcquisitionTypePipe} from '../pipes/acquisition-type.pipe';
import {AddNewsFileComponent} from "./add-news-file/add-news-file.component";
import {AddNewsItemComponent} from "./add-news-item/add-news-item.component";
import {MatRippleModule} from "@angular/material/core";

@NgModule({
  declarations: [
    PhotoPipe,
    PhotoSecuredPipe,
    PhotoProxyPipe,
    StatusPipe,
    AcquisitionTypePipe,
    TruncatePipe,
    JsonModalComponent,
    ConfirmDialog,
    AddLangDialogComponent,
    AddAuctionFileComponent,
    ChangeMenuPositionComponent,
    ChangePagePositionComponent,
    ChangeSliderPositionComponent,
    ImageUploadModalComponent,
    AddNewsFileComponent,
    AddNewsItemComponent,
  ],
  exports: [
    PhotoPipe,
    StatusPipe,
    AcquisitionTypePipe,
    PhotoSecuredPipe,
    PhotoProxyPipe,
    TruncatePipe,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTabsModule,
    FileUploadModule,
    ImageModule,
    DragDropModule,
    AuthorComponent,
    MatRippleModule,
  ]
})
export class ComponentsModule { }
