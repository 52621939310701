/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PressReleaseExcludingIdCreatorCreatedLangWithRelations } from './pressReleaseExcludingIdCreatorCreatedLangWithRelations';
import { ItemExcludingIdCreatorCreatedLangWithRelations } from './itemExcludingIdCreatorCreatedLangWithRelations';
import { AuctionExcludingIdCreatorCreatedLangWithRelations } from './auctionExcludingIdCreatorCreatedLangWithRelations';
import { ExhibitionLangExcludingIdCreatorCreatedLangWithRelations } from './exhibitionLangExcludingIdCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<ExhibitionWithRelations, \'id\' | \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'creator\', \'created\', \'lang\' ] })
 */
export interface ExhibitionExcludingIdCreatorCreatedLangWithRelations { 
    auctionId?: number;
    exhibitionFrom: string;
    exhibitionTo: string;
    mainExhibition?: boolean;
    matterportId?: string | null;
    youtubeVideoId?: string | null;
    archived?: boolean;
    status?: number;
    visible?: boolean;
    showPrices?: boolean | null;
    lastModified?: string | null;
    auction?: AuctionExcludingIdCreatorCreatedLangWithRelations;
    foreignKey?: any | null;
    lang?: ExhibitionLangExcludingIdCreatorCreatedLangWithRelations;
    langs?: Array<ExhibitionLangExcludingIdCreatorCreatedLangWithRelations>;
    items?: Array<ItemExcludingIdCreatorCreatedLangWithRelations>;
    pressReleases?: Array<PressReleaseExcludingIdCreatorCreatedLangWithRelations>;
}

