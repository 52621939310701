/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MenuItemLangWithRelations } from './menuItemLangWithRelations';


/**
 * (tsType: MenuItemWithRelations, schemaOptions: { includeRelations: true })
 */
export interface MenuItemWithRelations { 
    id?: number;
    menuId: number;
    filename?: string | null;
    targetWindow?: string | null;
    url?: string | null;
    icon?: string | null;
    pageId?: number | null;
    lft?: number;
    rgt?: number;
    lang?: MenuItemLangWithRelations;
    langs?: Array<MenuItemLangWithRelations>;
}

