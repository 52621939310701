/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TeamProjectExcludingIdWithRelations } from './teamProjectExcludingIdWithRelations';
import { TeamMemberLangExcludingIdWithRelations } from './teamMemberLangExcludingIdWithRelations';


/**
 * (tsType: Omit<TeamMemberWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface TeamMemberExcludingIdWithRelations { 
    firstname: string;
    lastname: string;
    titleBefore?: string | null;
    titleAfter?: string | null;
    email?: string;
    showMail?: boolean;
    maternityLeave?: boolean;
    filename?: string | null;
    order?: number | null;
    visible?: boolean;
    lang?: TeamMemberLangExcludingIdWithRelations;
    langs?: Array<TeamMemberLangExcludingIdWithRelations>;
    projects?: Array<TeamProjectExcludingIdWithRelations>;
}

