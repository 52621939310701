/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MenuItemWithRelations } from './menuItemWithRelations';


/**
 * (tsType: MenuWithRelations, schemaOptions: { includeRelations: true })
 */
export interface MenuWithRelations { 
    id?: number;
    name: string;
    code: string;
    visible?: boolean;
    nested?: Array<object>;
    items?: Array<MenuItemWithRelations>;
}

