/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ExhibitionExcludingIdVisibleStatusWithRelations } from './exhibitionExcludingIdVisibleStatusWithRelations';
import { AuctionStatusExcludingIdVisibleStatusWithRelations } from './auctionStatusExcludingIdVisibleStatusWithRelations';
import { ItemExcludingIdVisibleStatusWithRelations } from './itemExcludingIdVisibleStatusWithRelations';
import { AuctionLangExcludingIdVisibleStatusWithRelations } from './auctionLangExcludingIdVisibleStatusWithRelations';
import { AuctionFileExcludingIdVisibleStatusWithRelations } from './auctionFileExcludingIdVisibleStatusWithRelations';
import { PressReleaseExcludingIdVisibleStatusWithRelations } from './pressReleaseExcludingIdVisibleStatusWithRelations';


/**
 * (tsType: Omit<AuctionWithRelations, \'id\' | \'visible\' | \'status\'>, schemaOptions: { title: \'NewAuction\', includeRelations: true, exclude: [ \'id\', \'visible\', \'status\' ] })
 */
export interface NewAuction { 
    auctionNumber?: string | null;
    internetAuction?: boolean;
    fee?: number | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    youtubeVideoId?: string | null;
    mainAuction?: boolean;
    archived?: boolean;
    canvasId?: number | null;
    artslimitId?: number | null;
    exhibitionId?: number | null;
    canOrderCatalog?: boolean | null;
    created?: string;
    auctionStatus?: AuctionStatusExcludingIdVisibleStatusWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingIdVisibleStatusWithRelations;
    files?: Array<AuctionFileExcludingIdVisibleStatusWithRelations>;
    lang?: AuctionLangExcludingIdVisibleStatusWithRelations;
    langs?: Array<AuctionLangExcludingIdVisibleStatusWithRelations>;
    items?: Array<ItemExcludingIdVisibleStatusWithRelations>;
    pressReleases?: Array<PressReleaseExcludingIdVisibleStatusWithRelations>;
}

