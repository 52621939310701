import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import {RouterModule} from '@angular/router';
import {AdminService, FrontendService, SearchRequest} from "../../../../@backend";
import {ComponentsModule} from '../components.module';
import * as _ from 'underscore';
import {
  AuthorCreateEditModalComponent
} from '../../../pages/author/author-create-edit-modal/author-create-edit-modal.component';
import {MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {AuctionItemModalComponent} from '../../../pages/auction/auction-item-modal/auction-item-modal.component';
import {AuctionItemModalModule} from '../../../pages/auction/auction-item-modal/auction-item-modal.module';
import {MatSelectModule} from '@angular/material/select';
import {AuthorComponent} from '../author/author.component';
import {firstValueFrom} from "rxjs";

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule, RouterModule, ComponentsModule, MatDialogModule, AuctionItemModalModule, MatSelectModule, AuthorComponent],
  selector: 'kodl-search-modal',
  template: `
    <div>
      <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
        <mat-icon svgIcon="mat:search" class="text-secondary flex-none"></mat-icon>
        <input (keyup)="doSearch($event)" type="text" placeholder="Vyhledat..." class="text-xl font-medium bg-transparent outline-none flex-auto placeholder-secondary"/>
        <button class="flex-none ltr:-mr-2 rtl:-ml-2 text-secondary" type="button" mat-icon-button>
          <mat-icon svgIcon="mat:settings"></mat-icon>
        </button>
      </div>

<div class="searchResult" *ngIf="searchResult">
      <div class="p-4" *ngIf="searchResult.auctions && searchResult.auctions.length > 0">
        <div class="text-xs font-semibold text-secondary px-2 mb-2">Aukce</div>

        <div class="space-y-1">
          <div *ngFor="let auc of searchResult.auctions"
               class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
               [routerLink]="'/aukce/detail/' + auc.id"
               (click)="closeSearchModal()"
               matRipple>
            <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
              <mat-icon svgIcon="mat:gavel" class="icon-sm flex-none"></mat-icon>
            </div>
            <div class="flex-auto text-base font-medium">
              <div>{{auc.lang.name}}</div>
              <div class="text-secondary text-xs">{{auc.lang.name}}</div>
            </div>
            <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
          </div>
        </div>
      </div>

  <div class="p-4" *ngIf="searchResult.exhibitions && searchResult.exhibitions.length > 0">
    <div class="text-xs font-semibold text-secondary px-2 mb-2">Výstavy</div>

    <div class="space-y-1">
      <div *ngFor="let exh of searchResult.exhibitions"
           class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
           [routerLink]="'/vystavy/detail/' + exh.exhibitionId"
           (click)="closeSearchModal()"
           matRipple>
        <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
          <mat-icon svgIcon="mat:museum" class="icon-sm flex-none"></mat-icon>
        </div>
        <div class="flex-auto text-base font-medium">
          <div>{{exh.name}}</div>
          <!--              <div class="text-secondary text-xs">/apps/scrumboard</div>-->
        </div>
        <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
      </div>
    </div>
  </div>

  <div class="p-4" *ngIf="searchResult.items && searchResult.items.length > 0">
    <div class="text-xs font-semibold text-secondary px-2 mb-2">Položky</div>

    <div class="space-y-1">
      <div *ngFor="let item of searchResult.items"
           class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
           (click)="openItemDialog(item.id)"
           matRipple>
        <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
          <img [src]="item.pictures?.[0].filename + item.pictures?.[0].ext | photoProxy:'rs:fit:240:200:1/g:no':'items':'shared' | async" class="h-auto w-max align-middle">
        </div>
        <div class="flex-auto text-base font-medium">
          <div>{{item.lang.name}}</div>
          <div class="text-secondary text-xs">
            <strong><kodl-author [authors]="item.authors" [showDating]="true"></kodl-author></strong><br>
            <i>{{item.lang.dating}}</i>
          </div>
          <div class="text-secondary text-xs">{{item.auction?.lang?.name}}</div>
        </div>
        <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
      </div>
    </div>
  </div>

  <div class="p-4" *ngIf="searchResult.authors && searchResult.authors.length > 0">
    <div class="text-xs font-semibold text-secondary px-2 mb-2">Autoři</div>

    <div class="space-y-1">
      <div *ngFor="let author of searchResult.authors"
           class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
           (click)="openAuthorDialog(author.id)"
           matRipple>
        <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
<!--          <img [src]="item.pictures?.[0].filename + item.pictures?.[0].ext | photoProxy:'rs:fit:240:200:1/g:no':'items':'shared' | async" class="h-auto w-max align-middle">-->
        </div>
        <div class="flex-auto text-base font-medium">
          <div>{{author.nickname}}</div>
          <div class="text-secondary text-xs">
<!--            <strong><kodl-author [authors]="item.authors" [showDating]="true"></kodl-author></strong>-->
          </div>
<!--          <div class="text-secondary text-xs">{{item.auction.lang.name}}</div>-->
        </div>
        <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
      </div>
    </div>
  </div>
</div>
</div>
  `,
  styles: [`
  `]
})
export class SearchModalComponent implements OnInit {
  searchRequest: SearchRequest;
  // searchResult: Search; // TODO: fix, model returns auction[] instead of auctionWithRelations[]
  searchResult: any;

  loadingData = false;

  constructor(private adminService: AdminService, private frontendService: FrontendService, private dialog: MatDialog,  private dialogRef: MatDialogRef<SearchModalComponent>,) {
      this.searchRequest = {lang: 'cs'} as SearchRequest;
      this.getData = _.debounce(this.getData, 500);
  }

  ngOnInit(): void {
  }

  async getData(value: string) {
    this.loadingData = true;
    this.searchRequest.request = value;
    this.searchResult = await firstValueFrom(this.frontendService.searchControllerSearchGetAll("cs", value));
    console.log(this.searchResult);
    this.loadingData = false;
  }

  async doSearch($event: any) {
    const searchInput = $event.target.value as string;
    if(searchInput.length >= 2) {
      await this.getData(searchInput);
    }
  }

  closeSearchModal(): void {
    this.dialogRef.close(true);
  }


  openAuthorDialog(authorId: number) {
    this.dialog.open(AuthorCreateEditModalComponent, {data: {authorId:authorId}}).afterClosed().subscribe((result: any) => {
      if (result) {
        // this.getData(true);
      }
    });
  }

  async openItemDialog(id: number) {
    this.dialog.open(AuctionItemModalComponent, {data: {itemId: id}})
      .afterClosed()
      .subscribe(bSuccess => {

      });
  }

}
