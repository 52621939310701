import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {

  // private langs: {
  //   lang1: {
  //     new: 'Nová';
  //   }
  // }
  private czStatus = {new: 'Nová', inProgress: 'Rozpracovaná', done: 'Hotová', closed: 'Uzavřená'};
  transform(value: string, langId = 1): string {
    if(this?.czStatus[value]) {
      return this.czStatus[value];
    }else{
      return value;
    }
  }
}
