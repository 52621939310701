import { Injectable } from '@angular/core';
import {LoginRequest, UserManagementService} from "../../../@backend";
import {LocalUser} from '../models/localUser';
import jwt_decode from 'jwt-decode';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUser: LocalUser;
    public isLoggedIn: boolean;

    constructor(private userService: UserManagementService) {
        this.currentUser = JSON.parse(localStorage.getItem('currentWebUser'));
        if (this.currentUser){
          this.isLoggedIn = true;
        }else{
          this.isLoggedIn = false;
        }
    }

    public get currentUserValue(): LocalUser {
        return this.currentUser;
    }
    async login(email, password, auth2FAToken: string = null): Promise<boolean|object>{

      let data;
      if(auth2FAToken) {
        data = {email, password, auth2FAToken} as LoginRequest;
      }else{
        data = {email, password} as LoginRequest;
      }
      return this.userService.userControllerLogin(data)
        .toPromise()
        .catch((reason => {
          return {errMessage: reason.error.error.message};
        }))
        .then((response: any) => {
          if (response.token) {
            const decodedJWT = jwt_decode(response.token) as any;
            this.currentUser = {token: response.token, id: decodedJWT.id, exp: decodedJWT.exp, firstname: decodedJWT.firstname, lastname: decodedJWT.lastname, role: decodedJWT.role, email: decodedJWT.email, iat: decodedJWT.iat} as LocalUser;
            this.isLoggedIn = true;
            localStorage.setItem('currentWebUser', JSON.stringify(this.currentUser));
            return true;
          }else{
            return response;
          }
        });
    }
    // isMyTo

    logout(): any {
        localStorage.removeItem('currentWebUser');
        this.isLoggedIn = false;
        this.currentUser = null;
    }
}
