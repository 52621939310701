/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NewsAttachmentExcludingIdCreatorCreatedLangWithRelations } from './newsAttachmentExcludingIdCreatorCreatedLangWithRelations';
import { ItemExcludingIdCreatorCreatedLangWithRelations } from './itemExcludingIdCreatorCreatedLangWithRelations';
import { NewsLangExcludingIdCreatorCreatedLangWithRelations } from './newsLangExcludingIdCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<NewsWithRelations, \'id\' | \'creator\' | \'created\' | \'lang\'>, schemaOptions: { title: \'NewNews\', includeRelations: true, exclude: [ \'id\', \'creator\', \'created\', \'lang\' ] })
 */
export interface NewNews { 
    published: boolean;
    pinned?: boolean | null;
    publishDate?: string | null;
    unpublishDate?: string | null;
    lang?: NewsLangExcludingIdCreatorCreatedLangWithRelations;
    langs?: Array<NewsLangExcludingIdCreatorCreatedLangWithRelations>;
    attachments?: Array<NewsAttachmentExcludingIdCreatorCreatedLangWithRelations>;
    items?: Array<ItemExcludingIdCreatorCreatedLangWithRelations>;
}

