import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LangsService} from '../../services/langs.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {AdminService, MenuItemWithRelations, MenuWithRelations, LangWithRelations} from "../../../../@backend";

@Component({
  selector: 'app-change-menu-position',
  templateUrl: './change-menu-position.component.html',
  styleUrls: ['./change-menu-position.component.scss']
})
export class ChangeMenuPositionComponent {

  public langs: LangWithRelations[];
  form = this.fb.group({
    menuItemId: [null, Validators.required]
  });
  menuItem: MenuItemWithRelations;
  menuItems: any;
  menuItemsForSelect: any;

  loading: boolean = true;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangeMenuPositionComponent>,
    private langService: LangsService,
    private fb: UntypedFormBuilder,
    private adminService: AdminService,
  ) {
    this.menuItem = this.data;
    this.init();

  }

  async init() {
    this.loading = true;
    this.menuItems = await this.adminService.menuControllerGetMenuById(this.menuItem.menuId).toPromise() as MenuWithRelations;
    console.log(this.menuItems);
    this.menuItemsForSelect = this.flattenObject(this.menuItems.nested);
    // this.menuItemsForSelect = this.flattenObject2(this.menuItems.nested);
    console.log(this.menuItemsForSelect);
    this.loading = false;
  }

  async save() {
    const behindMenuItemId = this.form.value.menuItemId;
    console.log(behindMenuItemId);
    await this.adminService.menuControllerChangeMenuPosition(this.menuItem.menuId, this.menuItem.id, behindMenuItemId).toPromise();
    this.dialogRef.close(true);
  }


  flattenObject(items: any) {
    return items;
  }





}
