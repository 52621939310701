/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PressReleasePartialExcludingAuctionIdWithRelations } from './pressReleasePartialExcludingAuctionIdWithRelations';
import { ExhibitionLangPartialExcludingAuctionIdWithRelations } from './exhibitionLangPartialExcludingAuctionIdWithRelations';
import { AuctionPartialExcludingAuctionIdWithRelations } from './auctionPartialExcludingAuctionIdWithRelations';
import { ItemPartialExcludingAuctionIdWithRelations } from './itemPartialExcludingAuctionIdWithRelations';


/**
 * (tsType: Omit<Partial<ExhibitionWithRelations>, \'auctionId\'>, schemaOptions: { includeRelations: true, exclude: [ \'auctionId\' ], partial: true })
 */
export interface ExhibitionPartialExcludingAuctionIdWithRelations { 
    id?: number;
    exhibitionFrom?: string;
    exhibitionTo?: string;
    mainExhibition?: boolean;
    matterportId?: string | null;
    youtubeVideoId?: string | null;
    archived?: boolean;
    status?: number;
    visible?: boolean;
    showPrices?: boolean | null;
    created?: string;
    lastModified?: string | null;
    auction?: AuctionPartialExcludingAuctionIdWithRelations;
    foreignKey?: any | null;
    lang?: ExhibitionLangPartialExcludingAuctionIdWithRelations;
    langs?: Array<ExhibitionLangPartialExcludingAuctionIdWithRelations>;
    items?: Array<ItemPartialExcludingAuctionIdWithRelations>;
    pressReleases?: Array<PressReleasePartialExcludingAuctionIdWithRelations>;
}

