/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<NewMenuItem, \'id\'>, schemaOptions: { title: \'NewMenuItem\', exclude: [ \'id\' ] })
 */
export interface NewMenuItem { 
    menuId: number;
    filename?: string | null;
    targetWindow?: string | null;
    url?: string | null;
    icon?: string | null;
    pageId?: number | null;
    lft?: number;
    rgt?: number;
    parentId?: number | null;
}

