/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { BlogPostExcludingIdWithRelations } from './blogPostExcludingIdWithRelations';
import { BlogPostCategoryLangExcludingIdWithRelations } from './blogPostCategoryLangExcludingIdWithRelations';


/**
 * (tsType: Omit<BlogPostCategoryWithRelations, \'id\'>, schemaOptions: { title: \'NewBlogPostCategory\', includeRelations: true, exclude: [ \'id\' ] })
 */
export interface NewBlogPostCategory { 
    blogPostId?: number;
    langs?: Array<BlogPostCategoryLangExcludingIdWithRelations>;
    lang?: BlogPostCategoryLangExcludingIdWithRelations;
    posts?: Array<BlogPostExcludingIdWithRelations>;
}

