/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<ItemPictureWithRelations, \'langs\'>, schemaOptions: { includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface ItemPictureExcludingLangsWithRelations { 
    id?: string;
    itemId: number;
    filename: string;
    ext: string;
    originalName?: string;
    created?: string;
    order?: number;
    visible?: boolean;
}

