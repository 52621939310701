import {Injectable, NgZone} from '@angular/core';
import {TinyMCE} from 'tinymce';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdminService, Page} from "../../../@backend";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {AssetManagerModalComponent} from "../components/asset-manager-modal/asset-manager-modal.component";
import {Asset, AssetPreviewComponent} from "../components/asset-preview/asset-preview.component";

declare type EditorOptions = Parameters<TinyMCE['init']>[0];

@Injectable({ providedIn: 'root' })
export class TinyService {

  constructor( private snackBar: MatSnackBar,
               private zone: NgZone,
               private dialog: MatDialog,
               private adminService: AdminService,) {
  }
  editorInstance: any;

  public modules(): EditorOptions {
    return {
      plugins: 'link code image fullscreen template table',
      menubar: false,
      templates: this.getTemplates(),
      link_list: async (success: any) => {
         const pages = await this.getLinkList();
         success(pages);
      },
      content_langs: [
        { title: 'Čeština', code: 'cs' },
        { title: 'English', code: 'en' },
      ],
      branding: false,
      language_url: '/assets/js/cs.js',
      toolbar: 'undo redo | bold italic underline | blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist table | forecolor casechange permanentpen formatpainter removeformat hr | assetsManager template link | fullscreen code',
      images_upload_handler: (blobInfo) => {
        return this.imageUpload(blobInfo);
      },
      setup: (editor: any) => {
        this.editorInstance = editor;
        editor.ui.registry.addButton('assetsManager', {
          icon: 'image',
          tooltip: 'Správce souborů',
          onAction: () => {
            this.openAssetManagerDialog();
          }
        });

        editor.on('DblClick', (event: any) => {
          const targetElement = event.target;

          // Check if the double-clicked element is an image
          if (targetElement.nodeName === 'IMG') {
            console.log(targetElement);
            console.log(event);
            const imageUrl = targetElement.getAttribute('src');
            const width = Number(targetElement.getAttribute('width'));
            const height = Number(targetElement.getAttribute('height'));
            const resize = targetElement.getAttribute('data-resize');
            const filename = targetElement.getAttribute('data-filename');
            console.log(width, height);
            console.log(resize);
            console.log(filename);
            this.openAssetPreviewDialog(imageUrl, width, height, resize, filename);
          }
        });

        // Handle image resize event
        editor.on('ObjectResized', (event: any) => {

          //TODO: Resize image, parse url and change width and height
          console.log(event.width);
          console.log(event.height);

          // const imageElement = event.target;
          //
          // // Retrieve the new size of the image
          // const newSize = {
          //   width: imageElement.width(),
          //   height: imageElement.height()
          // };
          //
          // // Perform actions with the new size
          // console.log('Image resized. New size:', newSize);
        });

      }
    };
  }

  public modulesEasy(): EditorOptions {
    return {
      plugins: 'link table',
      menubar: false,
      branding: false,
      toolbar: 'undo redo | bold italic underline | blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist table | image template link | forecolor casechange permanentpen formatpainter removeformat hr',
      images_upload_handler: (blobInfo) => {
        return this.imageUpload(blobInfo);
      },
    };
  }

  public modulesText(): EditorOptions {
    return {
      plugins: '',
      menubar: false,
      toolbar: 'undo redo | bold italic underline | blocks |',
      branding: false,
    };
  }

  getTemplates() {
    return [
      {
        title: 'Ukazková tailwind template',
        description: 'Toto je ukázkový template.',
        content: '<div class="flex items-stretch"><div class="w-1/3">1</div><div class="w-1/3">2</div><div class="w-1/3">3</div></div>'
      }
    ]
  }

  async getLinkList() {
    console.log('getting links');
    const pages = await firstValueFrom(this.adminService.pageControllerAdminGetAllPageEntitiesForSelect());
    console.log(pages);
    const tinyMcePages = [];
    pages.forEach((page: Page) => {
      tinyMcePages.push({title: page.name, value: `${environment.frontendUrl}/stranka/${page.slug}`});
    });

    return tinyMcePages;
  }

  imageUpload(blobInfo: any) {
    const file = blobInfo.blob();
    const filePath = `${Date.now()}-${blobInfo.filename()}`;
    return new Promise<string>(async (resolve, reject) => {
      try {
        const result = await firstValueFrom(this.adminService.assetManagerControllerAdminUploadAsset(file));
        if (result?.filename && result?.url) {
          resolve(result.url);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  openAssetManagerDialog(): void {
    this.zone.run(() => {
      this.dialog.open(AssetManagerModalComponent, {
        data: {}
      }).afterClosed().subscribe((result: Asset) => {
        if(result) {
          this.editorInstance.execCommand('mceInsertContent', false, `<img src="${result.url}" width="${result.width}" height="${result.height}" data-filename="${result.filename}" data-resize="${result.resizingType}" alt=""/>`);
        }
      });
    });
  }

  openAssetPreviewDialog(url: string, width: number, height: number, resize: string, filename: string): void {
    this.zone.run(() => {
      this.dialog.open(AssetPreviewComponent, {
        maxHeight: '90vh',
        data: {
          url,
          width,
          height,
          resizingType: resize,
          filename
        }
      }).afterClosed().subscribe((result: Asset) => {
        if(result) {
          this.editorInstance.execCommand('mceInsertContent', false, `<img src="${result.url}" width="${result.width}" height="${result.height}" data-filename="${result.filename}" data-resize="${result.resizingType}" alt=""/>`);
        }
      });
    });
  }

}
