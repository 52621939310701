/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<Partner, \'name\' | \'filename\' | \'link\' | \'visible\'>, schemaOptions: { title: \'PartnerOrderUpdate\', includeRelations: false, exclude: [ \'name\', \'filename\', \'link\', \'visible\' ] })
 */
export interface PartnerOrderUpdate { 
    id?: number;
    rank?: number;
}

