import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LangsService} from '../../../shared/services/langs.service';
import {
  AdminService,
  AuthorWithRelations,
  ItemLangWithRelations,
  ItemWithRelations,
} from '../../../../@backend';
import {ItemService} from '../../../shared/services/item.service';
import {TinyService} from '../../../shared/services/tiny.service';
import {ImagePreviewComponent} from "../../../shared/components/image-preview/image-preview.component";

@Component({
  selector: 'kodl-auction-item-modal',
  templateUrl: './auction-item-modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AuctionItemModalComponent implements OnInit {

  form: FormGroup;
  mode: 'create' | 'update' = 'create';
  item: ItemWithRelations;
  itemId: number;
  auctionId: number;
  exhibitionId: number;
  authors: AuthorWithRelations[];
  loading: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AuctionItemModalComponent>,
              public langsService: LangsService,
              private adminService: AdminService,
              private dialog: MatDialog,
              public localItemService: ItemService,
              public tinyService: TinyService,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.loading = true;
    this.init().then(() => {this.loading = false});
  }

  async init() {
    this.authors = await this.adminService.authorControllerAdminGetAllAuthors().toPromise();
    if(this.data?.itemId) {
      this.itemId = this.data.itemId;
      this.item = await this.adminService.auctionControllerAdminGetAuctionItem(this.itemId).toPromise();
      this.mode = 'update';
    }else if(this.data?.auctionId || this.data?.exhibitionId){
      this.createDefaultEntity();
      this.item.auctionId = this.data.auctionId;
      this.item.exhibitionId = this.data.exhibitionId;
      this.mode = 'create';
    }

    this.form = this.fb.group({
      size: [this.item.size || null],
      priceEstimationLow: [this.item.priceEstimationLow || null],
      priceEstimationHigh: [this.item.priceEstimationHigh || null],
      // priceCurrent: [this.item.priceCurrent || null],
      priceHammer: [this.item.priceHammer || null],
      priceFinal: [this.item.priceFinal || null],
      startPriceForBidding: [this.item.startPriceForBidding || null],
      catalogueNumber: [this.item.catalogueNumber || null],
      authors: [this.item.authors || null, Validators.required],
      status: [this.item.status || null],
    });
  }

  createDefaultEntity() {
    this.item = {langs: [] as ItemLangWithRelations[]} as ItemWithRelations;
    for (const lang of this.langsService.langs) {
      this.item.langs.push({langId: lang.id} as ItemLangWithRelations);
    }
  }

  save(saveAndClose = true) {
    if(this.form.valid) {
      // const bValidation = this.customValidation();
      if (this.mode === 'create') {
        this.createItem(saveAndClose);
      } else if (this.mode === 'update') {
        this.updateItem(saveAndClose);
      }
    }else{
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control.markAsTouched({ onlySelf: true });
      });
      return false;
    }
  }

  async createItem(saveAndClose = false) {
    const item = { ...this.item, ...this.form.value };
    try {
      const bSuccess = await this.adminService.auctionControllerAdminCreateItem(item).toPromise();
      if(bSuccess) {
        if(saveAndClose) this.dialogRef.close(true);
      }
    }catch (e) {
      console.log(e);
    }
  }

  async updateItem(saveAndClose = true) {
    const { pictures, ...itemWOPics } = this.item;
    const item = { ...itemWOPics, ...this.form.value };
    try {
      const bSuccess = await this.adminService.auctionControllerAdminUpdateItem(item).toPromise();
      if(bSuccess) {
        if(saveAndClose) this.dialogRef.close(true);
      }
    }catch (e) {
      console.log(e);
    }
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  isValid(): boolean {
    if(this.form.valid && this.customValidation()){
      return true;
    }else{
      return false;
    }
  }
  customValidation(): boolean {
    for (const lang of this.item.langs) {
      if(lang.name === null || lang.name === undefined) {
        return false;
      }
      if(lang.dating === null || lang.dating === undefined) {
        return false;
      }
      if(lang.technique === null || lang.technique === undefined) {
        return false;
      }
    }
    return true;
  }

  public isSelected(a1: any, a2: any) {
    return a1 && a2 && a1.id === a2.id;
  }

  async openPhotoPreview(photo: string, shared: boolean) {
    this.dialog.open(ImagePreviewComponent, {
      data: {photo, shared}
    }).afterClosed().subscribe(() => {
    });
  }

}
