/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: CanvasAuthorWithRelations, schemaOptions: { includeRelations: true })
 */
export interface CanvasAuthorWithRelations { 
    id?: number;
    firstname?: string;
    lastname?: string;
    nickname?: string;
    birthday?: string;
    death?: string;
    descriptionCs?: string;
    descriptionEn?: string;
    status?: number;
}

