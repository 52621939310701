/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ExhibitionLang } from './exhibitionLang';
import { Item } from './item';
import { Auction } from './auction';
import { Author } from './author';
import { NewsLang } from './newsLang';


export interface Search { 
    auctions?: Array<Auction>;
    items?: Array<Item>;
    exhibitions?: Array<ExhibitionLang>;
    news?: Array<NewsLang>;
    authors?: Array<Author>;
}

