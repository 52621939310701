/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorExcludingIdCreatorCreatedLangWithRelations } from './authorExcludingIdCreatorCreatedLangWithRelations';
import { ItemExcludingIdCreatorCreatedLangWithRelations } from './itemExcludingIdCreatorCreatedLangWithRelations';
import { AuthorRecordsLangExcludingIdCreatorCreatedLangWithRelations } from './authorRecordsLangExcludingIdCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<AuthorRecordsWithRelations, \'id\' | \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'creator\', \'created\', \'lang\' ] })
 */
export interface AuthorRecordsExcludingIdCreatorCreatedLangWithRelations { 
    authorId?: number;
    itemId?: number;
    type: AuthorRecordsExcludingIdCreatorCreatedLangWithRelations.TypeEnum;
    position?: number;
    author?: AuthorExcludingIdCreatorCreatedLangWithRelations;
    foreignKey?: any | null;
    item?: ItemExcludingIdCreatorCreatedLangWithRelations;
    lang?: AuthorRecordsLangExcludingIdCreatorCreatedLangWithRelations;
    langs?: Array<AuthorRecordsLangExcludingIdCreatorCreatedLangWithRelations>;
}
export namespace AuthorRecordsExcludingIdCreatorCreatedLangWithRelations {
    export type TypeEnum = 'local' | 'europe' | 'worldwide';
    export const TypeEnum = {
        Local: 'local' as TypeEnum,
        Europe: 'europe' as TypeEnum,
        Worldwide: 'worldwide' as TypeEnum
    };
}


