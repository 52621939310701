/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * @type UserScopeFilterFields
 * @export
 */
export type UserScopeFilterFields = Set<string> | { [key: string]: any; };

