<h1 mat-dialog-title>Propojení položek s novinkou</h1>
<div mat-dialog-content>
  <div class="flex flex-col items-center m-2">
    <div class="flex items-center gap-4 px-6 py-3 border-b border-divider">
      <mat-icon svgIcon="mat:search" class="text-secondary flex-none"></mat-icon>
      <input (keyup)="doSearch($event)" type="text" placeholder="Vyhledat..." class="text-xl font-medium bg-transparent outline-none flex-auto placeholder-secondary"/>
<!--      <button class="flex-none ltr:-mr-2 rtl:-ml-2 text-secondary" type="button" mat-icon-button>-->
<!--        <mat-icon svgIcon="mat:settings"></mat-icon>-->
<!--      </button>-->
    </div>

    <div class="space-y-1">
      <div *ngFor="let item of items"
           class="px-2 py-2 hover:bg-hover rounded transition duration-200 ease-out flex items-center gap-4 cursor-pointer select-none"
           (click)="addConnection(item.id)"
           matRipple>
        <div class="flex items-center justify-center w-8 h-8 rounded-full bg-foreground/20">
          <img [src]="item.pictures?.[0].filename + item.pictures?.[0].ext | photoProxy:'rs:fit:240:200:1/g:no':'items':'shared' | async" class="h-auto w-max align-middle">
        </div>
        <div class="flex-auto text-base font-medium">
          <div>{{item.lang.name}}</div>
          <div class="text-secondary text-xs">
            <strong><kodl-author [authors]="item.authors" [showDating]="true"></kodl-author></strong><br>
            <i>{{item.lang.dating}}</i>
          </div>
          <div class="text-secondary text-xs">{{item.auction?.lang?.name}}</div>
        </div>
        <mat-icon svgIcon="mat:chevron_right" class="icon-sm flex-none"></mat-icon>
      </div>
    </div>

  </div>
</div>
<div mat-dialog-actions class="flex w-full justify-center gap-2">
  <button mat-flat-button color="accent" (click)="add()">Přidat</button>
  <button mat-flat-button color="primary" (click)="dialogRef.close(false)" cdkFocusInitial>Zrušit</button>
</div>
