/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<Menu, \'id\' | \'items\'>, schemaOptions: { title: \'NewMenu\', exclude: [ \'id\', \'items\' ] })
 */
export interface NewMenu { 
    name: string;
    code: string;
    visible?: boolean;
    nested?: Array<object>;
}

