/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorRecordsExcludingLangsWithRelations } from './authorRecordsExcludingLangsWithRelations';
import { ItemStatusExcludingLangsWithRelations } from './itemStatusExcludingLangsWithRelations';
import { ItemPictureExcludingLangsWithRelations } from './itemPictureExcludingLangsWithRelations';
import { ExhibitionExcludingLangsWithRelations } from './exhibitionExcludingLangsWithRelations';
import { AuctionExcludingLangsWithRelations } from './auctionExcludingLangsWithRelations';
import { ItemLangExcludingLangsWithRelations } from './itemLangExcludingLangsWithRelations';
import { AuthorExcludingLangsWithRelations } from './authorExcludingLangsWithRelations';


/**
 * (tsType: Omit<ItemWithRelations, \'langs\'>, schemaOptions: { includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface ItemExcludingLangsWithRelations { 
    id?: number;
    auctionId?: number;
    exhibitionId?: number;
    size?: string | null;
    priceEstimationLow?: number | null;
    priceEstimationHigh?: number | null;
    priceCurrent?: number | null;
    priceHammer?: number | null;
    priceFinal?: number | null;
    startPriceForBidding?: number | null;
    startPriceForClosing?: number | null;
    catalogueNumber?: number | null;
    canvasId?: number | null;
    artslimitId?: number | null;
    created?: string;
    lastModified?: string;
    status?: number;
    auction?: AuctionExcludingLangsWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingLangsWithRelations;
    lang?: ItemLangExcludingLangsWithRelations;
    langs?: Array<ItemLangExcludingLangsWithRelations>;
    pictures?: Array<ItemPictureExcludingLangsWithRelations>;
    authors?: Array<AuthorExcludingLangsWithRelations>;
    itemStatus?: ItemStatusExcludingLangsWithRelations;
    record?: AuthorRecordsExcludingLangsWithRelations;
}

