import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LangsService} from '../../services/langs.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {
  AdminService,
  SliderItemOrderUpdate,
  SliderWithRelations
} from "../../../../@backend";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-change-slider-position',
  templateUrl: './change-slider-position.component.html',
  styleUrls: ['./change-slider-position.component.scss']
})
export class ChangeSliderPositionComponent {

  slider: SliderWithRelations;
  sliderId: number;

 newOrderArray = [] as SliderItemOrderUpdate[];

  loading: boolean = true;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangeSliderPositionComponent>,
    private langService: LangsService,
    private fb: UntypedFormBuilder,
    private adminService: AdminService,
  ) {
    if(this.data?.id) {
      this.sliderId = this.data.id;
      this.init();
    }else{
      this.dialogRef.close(false);
    }
  }

  async init() {
    this.loading = true;
    this.slider = await this.adminService.sliderControllerGetSliderById(this.sliderId).toPromise() as SliderWithRelations;
    console.log(this.slider);
    this.loading = false;
  }

  async save() {
    const res = await this.adminService.sliderControllerAdminChangeSliderItemsOrder(this.sliderId, this.newOrderArray).toPromise();
    if(res) {
      this.dialogRef.close(res);
    }else {
      this.dialogRef.close(true);
    }
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.slider.items, event.previousIndex, event.currentIndex);
    this.recalculateRank();
  }

  async recalculateRank() {
    this.newOrderArray = [] as SliderItemOrderUpdate[];
    for(var index in this.slider.items) {
      this.slider.items[index].rank = Number(index) + 1;
      this.newOrderArray.push({id: this.slider.items[index].id, rank: this.slider.items[index].rank});
    }

  }




}
