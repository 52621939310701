/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PressReleaseLangExcludingIdVisibleStatusWithRelations } from './pressReleaseLangExcludingIdVisibleStatusWithRelations';


/**
 * (tsType: Omit<PressReleaseWithRelations, \'id\' | \'visible\' | \'status\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'visible\', \'status\' ] })
 */
export interface PressReleaseExcludingIdVisibleStatusWithRelations { 
    auctionId?: number | null;
    exhibitionId?: number | null;
    releaseDate: string;
    created?: string;
    lang?: PressReleaseLangExcludingIdVisibleStatusWithRelations;
    langs?: Array<PressReleaseLangExcludingIdVisibleStatusWithRelations>;
}

