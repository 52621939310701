  <div class="flex items-center" mat-dialog-title>
    <h2 *ngIf="mode === 'add'" class="headline m-0 flex-auto">Nahrání obrázku</h2>
    <h2 *ngIf="mode === 'change'" class="headline m-0 flex-auto">Úprava obrázku</h2>

    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>

  </div>

  <mat-divider class="text-border"></mat-divider>

  <div mat-dialog-content class="flex flex-col">
    <div class="mt-6 flex flex-col flex-wrap items-center justify-center" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!loading">

<!--    <form [formGroup]="form">-->

<!--      <div class="flex flex-col sm:flex-row sm:gap-4">-->
<!--          <mat-form-field class="flex-auto">-->
<!--            <mat-label>Název</mat-label>-->
<!--            <input formControlName="name" matInput>-->
<!--          </mat-form-field>-->
<!--          <mat-form-field class="flex-auto">-->
<!--            <mat-label>Kód</mat-label>-->
<!--            <input formControlName="code" matInput>-->
<!--            <mat-icon class="mr-3" matPrefix svgIcon="mat:code"></mat-icon>-->
<!--          </mat-form-field>-->
<!--      </div>-->
<!--    </form>-->

    <div class="p-2">
      <div class="flex flex-row sm:flex-row sm:gap-2 place-content-center items-center">
        <p-image *ngIf="fileUrl" [preview]="true" [src]="fileUrl" class="block h-full w-full object-cover object-center cursor-pointer" width="250"></p-image>
        <p-fileUpload mode="basic" #imageModalButton [customUpload]="true" (uploadHandler)="processUpload($event, imageModalButton)" [name]="'blockimage'" [auto]="true" accept="image/*" chooseLabel="Nahrát obrázek"></p-fileUpload>
      </div>
      <div class="flex w-full items-center text-center">
      </div>
    </div>
</div>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Zavřít</button>
    <button color="primary" mat-flat-button (click)="save()" type="submit">Uložit</button>
  </div>
