/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<AuctionLangWithRelations, \'id\'>, schemaOptions: { exclude: [ \'id\' ], includeRelations: true })
 */
export interface AuctionLangExcludingIdWithRelations { 
    auctionId?: number;
    langId: number;
    name: string;
    description?: string | null;
}

