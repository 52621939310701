import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {AssetFileUploaded, AssetManagerService} from "../../../../@backend";
import {MatSnackBar} from '@angular/material/snack-bar';
import {LangsService} from '../../services/langs.service';
import {FileUpload, FileUploadModule} from 'primeng/fileupload';
import {firstValueFrom} from "rxjs";
import {CommonModule} from "@angular/common";
import {ImageModule} from "primeng/image";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {ComponentsModule} from "../components.module";
import {MatTooltip} from "@angular/material/tooltip";
import {Asset, AssetPreviewComponent} from "../asset-preview/asset-preview.component";
import {AssetUploadComponent} from "../asset-upload/asset-upload.component";

@Component({
  selector: 'kodl-asset-manager-modal',
  standalone: true,
  imports: [
    CommonModule, MatDialogModule, FileUploadModule, FormsModule, ReactiveFormsModule, MatInputModule, ImageModule, MatButtonModule, MatInputModule, MatProgressSpinnerModule, MatIconModule, MatDividerModule, ComponentsModule, MatTooltip
  ],
  templateUrl: './asset-manager-modal.component.html',
})
export class AssetManagerModalComponent implements OnInit {
  loading: boolean = true;
  assets: string[] = [];

  file: any;
  fileUrl: string;

  form: FormGroup = this.fb.group({
    // name: ['', Validators.required],
    // code: ['', Validators.required]
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AssetManagerModalComponent>,
              private dialog: MatDialog,
              private fb: FormBuilder,
              public langsService: LangsService,
              private assetManagerService: AssetManagerService,) {
  }



  async ngOnInit(): Promise<void>{
    this.loading = true;
    this.assets = await firstValueFrom(this.assetManagerService.assetManagerControllerGetAllAssets());
    this.loading = false;
  }

  addAsset() {
    this.dialog.open(AssetUploadComponent, {}).afterClosed().subscribe((result: AssetFileUploaded) => {

      if(result.filename) {
        this.assets.push('assets/' + result.filename);
      }
    });
  }

  close() {
    this.dialogRef.close(undefined);
  }
  openAsset(asset: string) {
    this.dialog.open(AssetPreviewComponent, {
      maxHeight: '90vh',
      data: {
        filename: asset
      }
    }).afterClosed().subscribe((result: Asset) => {
      if(result) {
        this.dialogRef.close(result);
      }
    });
  }
}
