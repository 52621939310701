/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemExcludingIdVisibleStatusWithRelations } from './itemExcludingIdVisibleStatusWithRelations';
import { AuctionExcludingIdVisibleStatusWithRelations } from './auctionExcludingIdVisibleStatusWithRelations';
import { ExhibitionLangExcludingIdVisibleStatusWithRelations } from './exhibitionLangExcludingIdVisibleStatusWithRelations';
import { PressReleaseExcludingIdVisibleStatusWithRelations } from './pressReleaseExcludingIdVisibleStatusWithRelations';


/**
 * (tsType: Omit<ExhibitionWithRelations, \'id\' | \'visible\' | \'status\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'visible\', \'status\' ] })
 */
export interface ExhibitionExcludingIdVisibleStatusWithRelations { 
    auctionId?: number;
    exhibitionFrom: string;
    exhibitionTo: string;
    mainExhibition?: boolean;
    matterportId?: string | null;
    youtubeVideoId?: string | null;
    archived?: boolean;
    showPrices?: boolean | null;
    created?: string;
    lastModified?: string | null;
    auction?: AuctionExcludingIdVisibleStatusWithRelations;
    foreignKey?: any | null;
    lang?: ExhibitionLangExcludingIdVisibleStatusWithRelations;
    langs?: Array<ExhibitionLangExcludingIdVisibleStatusWithRelations>;
    items?: Array<ItemExcludingIdVisibleStatusWithRelations>;
    pressReleases?: Array<PressReleaseExcludingIdVisibleStatusWithRelations>;
}

