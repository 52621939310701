/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<ItemStatusWithRelations, \'id\' | \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'creator\', \'created\', \'lang\' ] })
 */
export interface ItemStatusExcludingIdCreatorCreatedLangWithRelations { 
    code: string;
}

