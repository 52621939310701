import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  AdminService, ItemWithRelations, NewsItem, SearchRequest
} from "../../../../@backend";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-add-news-item',
  templateUrl: './add-news-item.component.html',
  styleUrls: ['./add-news-item.component.scss']
})
export class AddNewsItemComponent {

  newsId: number;
  loading: boolean = true;
  searchRequest: SearchRequest = {lang: 'cs'} as SearchRequest;
  items: ItemWithRelations[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddNewsItemComponent>,
    private adminService: AdminService,
  ) {
    if(data?.newsId) {
      this.newsId = data.newsId;
    }else{
      console.log('Missing news ID');
    }

  }

  async doSearch($event: any) {
    const searchInput = $event.target.value as string;
    if(searchInput.length >= 2) {
      await this.getData(searchInput);
    }
  }

  async getData(value: string) {
    this.loading = true;
    this.searchRequest.request = value;
    this.items = await firstValueFrom(this.adminService.searchControllerSearchItems(this.searchRequest));
    this.loading = false;
  }

  async add() {
  }

  async addConnection(id: number) {
    const newItemConnection = await firstValueFrom(this.adminService.newsControllerAdminCreateNewsItemConnection({newsId: this.newsId, itemId: id} as NewsItem));
    this.dialogRef.close(true);
  }
}
