/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { TeamMemberLangExcludingLangsWithRelations } from './teamMemberLangExcludingLangsWithRelations';
import { TeamProjectExcludingLangsWithRelations } from './teamProjectExcludingLangsWithRelations';


/**
 * (tsType: Omit<TeamMemberWithRelations, \'langs\'>, schemaOptions: { includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface TeamMemberExcludingLangsWithRelations { 
    id?: number;
    firstname: string;
    lastname: string;
    titleBefore?: string | null;
    titleAfter?: string | null;
    email?: string;
    showMail?: boolean;
    maternityLeave?: boolean;
    filename?: string | null;
    order?: number | null;
    visible?: boolean;
    lang?: TeamMemberLangExcludingLangsWithRelations;
    langs?: Array<TeamMemberLangExcludingLangsWithRelations>;
    projects?: Array<TeamProjectExcludingLangsWithRelations>;
}

