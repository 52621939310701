/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DownloadItemLangWithRelations } from './downloadItemLangWithRelations';


/**
 * (tsType: DownloadItemWithRelations, schemaOptions: { includeRelations: true })
 */
export interface DownloadItemWithRelations { 
    id?: number;
    filename?: string | null;
    mime?: string | null;
    visible?: boolean;
    created?: string;
    lang?: DownloadItemLangWithRelations;
    langs?: Array<DownloadItemLangWithRelations>;
}

