/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { GalleryItemWithRelations } from './galleryItemWithRelations';


/**
 * (tsType: GalleryWithRelations, schemaOptions: { includeRelations: true })
 */
export interface GalleryWithRelations { 
    id?: number;
    name: string;
    code: string;
    newsId?: number;
    created?: string;
    visible: boolean;
    items?: Array<GalleryItemWithRelations>;
}

