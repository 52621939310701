import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import {VexRoutes} from '../@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import {AuthGuard} from './shared/guard/auth.guard';

const routes: VexRoutes = [
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "nastenka",
        redirectTo: "/",
        pathMatch: "full",
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "aukce",
        loadChildren: () =>
          import("./pages/auction/auction.module").then((m) => m.AuctionModule),
      },
      {
        path: "vystavy",
        loadChildren: () =>
          import("./pages/exhibition/exhibition.module").then(
            (m) => m.ExhibitionModule
          ),
      },
      {
        path: "autori",
        loadChildren: () =>
          import("./pages/author/author.module").then((m) => m.AuthorModule),
      },
      {
        path: "blog",
        loadChildren: () =>
          import("./pages/blog/blog.module").then((m) => m.BlogModule),
      },
      {
        path: "aktuality",
        loadChildren: () =>
          import("./pages/news/news.module").then((m) => m.NewsModule),
      },
      {
        path: "nas-tym",
        loadChildren: () =>
          import("./pages/team/team.module").then((m) => m.TeamModule),
      },
      {

        path: "tiskove-zpravy",
        loadChildren: () =>
          import("./pages/press-release/press-release.module").then(
            (m) => m.PressReleaseModule
          ),
      },
      {
        path: "formulare/nabidnuta-dila",
        loadChildren: () =>
          import("./pages/forms/item-offer/item-offer.module").then(
            (m) => m.ItemOfferModule
          ),
      },
      {
        path: "formulare/objednani-katalogu",
        loadChildren: () =>
          import("./pages/forms/catalog-order/catalog-order.module").then(
            (m) => m.CatalogOrderModule
          ),
      },
      {
        path: "reference",
        loadChildren: () =>
          import("./pages/reference/reference.module").then(
            (m) => m.ReferenceModule
          ),
      },
      {
        path: "obsah",
        loadChildren: () =>
          import("./pages/content/content.module").then((m) => m.ContentModule),
      },
      {
        path: "nastaveni/uzivatele-systemu",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "nastaveni/relink",
        loadChildren: () =>
          import("./pages/relink/relink.module").then((m) => m.RelinkModule),
      },
      {
        path: "blank",
        loadChildren: () =>
          import("./pages/blank/blank.module").then((m) => m.BlankModule),
      },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
