/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PageBlockLang } from './pageBlockLang';
import { PageBlockLangWithRelations } from './pageBlockLangWithRelations';


/**
 * (tsType: PageBlockWithRelations, schemaOptions: { includeRelations: true })
 */
export interface PageBlockWithRelations { 
    id?: number;
    pageId: number;
    name: string;
    code: string;
    type?: string;
    order?: number | null;
    content?: string | null;
    cssClass?: string | null;
    langs?: Array<PageBlockLang>;
    lang?: PageBlockLangWithRelations;
}

