/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NewsWithRelations } from './newsWithRelations';
import { UserCredentialsWithRelations } from './userCredentialsWithRelations';


/**
 * (tsType: UserWithRelations, schemaOptions: { includeRelations: true })
 */
export interface UserWithRelations { 
    id?: string;
    email: string;
    firstname?: string;
    lastname?: string;
    role?: string;
    photo?: string | null;
    phone?: string | null;
    note?: string | null;
    active?: boolean;
    createdDate?: string;
    auth2FAEnabled?: boolean;
    auth2FASecret?: string;
    auth2FAIV?: string;
    userCredentials?: UserCredentialsWithRelations;
    news?: Array<NewsWithRelations>;
}

