/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { PressReleaseLangExcludingLangsWithRelations } from './pressReleaseLangExcludingLangsWithRelations';


/**
 * (tsType: Omit<PressReleaseWithRelations, \'langs\'>, schemaOptions: { includeRelations: true, exclude: [ \'langs\' ] })
 */
export interface PressReleaseExcludingLangsWithRelations { 
    id?: number;
    auctionId?: number | null;
    exhibitionId?: number | null;
    releaseDate: string;
    visible?: boolean;
    created?: string;
    lang?: PressReleaseLangExcludingLangsWithRelations;
    langs?: Array<PressReleaseLangExcludingLangsWithRelations>;
}

