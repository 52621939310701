/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorRecordsWithRelations } from './authorRecordsWithRelations';
import { ItemStatusWithRelations } from './itemStatusWithRelations';
import { ExhibitionWithRelations } from './exhibitionWithRelations';
import { ItemLangWithRelations } from './itemLangWithRelations';
import { ItemPictureWithRelations } from './itemPictureWithRelations';
import { AuctionWithRelations } from './auctionWithRelations';
import { AuthorWithRelations } from './authorWithRelations';


/**
 * (tsType: ItemWithRelations, schemaOptions: { includeRelations: true })
 */
export interface ItemWithRelations { 
    id?: number;
    auctionId?: number;
    exhibitionId?: number;
    size?: string | null;
    priceEstimationLow?: number | null;
    priceEstimationHigh?: number | null;
    priceCurrent?: number | null;
    priceHammer?: number | null;
    priceFinal?: number | null;
    startPriceForBidding?: number | null;
    startPriceForClosing?: number | null;
    catalogueNumber?: number | null;
    canvasId?: number | null;
    artslimitId?: number | null;
    created?: string;
    lastModified?: string;
    status?: number;
    auction?: AuctionWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionWithRelations;
    lang?: ItemLangWithRelations;
    langs?: Array<ItemLangWithRelations>;
    pictures?: Array<ItemPictureWithRelations>;
    authors?: Array<AuthorWithRelations>;
    itemStatus?: ItemStatusWithRelations;
    record?: AuthorRecordsWithRelations;
}

