import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {AdminService, PageWithRelations, LangWithRelations} from "../../../../@backend";
import {LangsService} from "../../services/langs.service";

@Component({
  selector: 'app-change-page-position',
  templateUrl: './change-page-position.component.html',
  styleUrls: ['./change-page-position.component.scss']
})
export class ChangePagePositionComponent {

  public langs: LangWithRelations[];
  form = this.fb.group({
    pageItemId: [null, Validators.required]
  });
  pageItem: PageWithRelations;
  pages: PageWithRelations[];

  loading: boolean = true;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePagePositionComponent>,
    private langService: LangsService,
    private fb: UntypedFormBuilder,
    private adminService: AdminService,
  ) {
    this.pageItem = this.data;
    this.init();
    console.log(this.pageItem);

  }

  async init() {
    this.loading = true;
    this.pages = await this.adminService.pageControllerAdminGetAllPageEntities().toPromise() as PageWithRelations[];
    this.loading = false;
  }

  async save() {
    const behindPageItemId = this.form.value.pageItemId;
    await this.adminService.pageControllerChangePagePosition(this.pageItem.id, behindPageItemId).toPromise();
    this.dialogRef.close(true);
  }





}
