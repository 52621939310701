import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AdminService, AssetManagerService} from "../../../../@backend";
import {MatSnackBar} from '@angular/material/snack-bar';
import {LangsService} from '../../services/langs.service';
import {FileUpload, FileUploadModule} from 'primeng/fileupload';
import {CommonModule} from "@angular/common";
import {ImageModule} from "primeng/image";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'kodl-asset-upload',
  standalone: true,
  imports: [CommonModule, FileUploadModule, ImageModule, MatButtonModule, MatInputModule, FormsModule, ReactiveFormsModule, MatIconModule, MatDividerModule, MatProgressSpinnerModule],
  templateUrl: './asset-upload.component.html'
})
export class AssetUploadComponent implements OnInit {
  loading: boolean = true;
  type: string = 'image';

  file: any;
  fileUrl: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<AssetUploadComponent>,
              private snackBar: MatSnackBar,
              private assetManagerService: AssetManagerService,) {
  }

  ngOnInit() {
    this.loading = true;
    this.loading = false;
  }


  async save(): Promise<void> {
        try {
          const result = await firstValueFrom(this.assetManagerService.assetManagerControllerAdminUploadAsset(this.file));
          console.log(result);
          if(result?.filename && result?.url) {
            this.snackBar.open("Uspěšně uloženo", "OK", {duration: 2000});
            this.dialogRef.close(result);
          }
        }catch (e) {
          this.snackBar.open("Soubor se nepodařilo nahrát", "OK", {duration: 2000, panelClass: ['bg-red-600']});
        }
  }


  processUpload(event: any, button: FileUpload) {
    for(let file of event.files) {
      this.file = file;
    }
    button.clear();
    this.showPreviewOfImage();
  }

  showPreviewOfImage() {
    const reader = new FileReader();
    reader.onload = () => {
      this.fileUrl = reader.result as string;
    }
    reader.readAsDataURL(this.file);
  }


}
