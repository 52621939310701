/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuctionFileLangPartialExcludingAuctionIdWithRelations } from './auctionFileLangPartialExcludingAuctionIdWithRelations';


/**
 * (tsType: Omit<Partial<AuctionFileWithRelations>, \'auctionId\'>, schemaOptions: { includeRelations: true, exclude: [ \'auctionId\' ], partial: true })
 */
export interface AuctionFilePartialExcludingAuctionIdWithRelations { 
    id?: number;
    type?: AuctionFilePartialExcludingAuctionIdWithRelations.TypeEnum;
    mime?: string;
    created?: string;
    lang?: AuctionFileLangPartialExcludingAuctionIdWithRelations;
    langs?: Array<AuctionFileLangPartialExcludingAuctionIdWithRelations>;
}
export namespace AuctionFilePartialExcludingAuctionIdWithRelations {
    export type TypeEnum = 'catalogue' | 'limittable' | 'poatel' | 'poasite' | 'frontpage' | 'returnlist' | '1' | '2' | '3' | '4' | '5' | '6';
    export const TypeEnum = {
        Catalogue: 'catalogue' as TypeEnum,
        Limittable: 'limittable' as TypeEnum,
        Poatel: 'poatel' as TypeEnum,
        Poasite: 'poasite' as TypeEnum,
        Frontpage: 'frontpage' as TypeEnum,
        Returnlist: 'returnlist' as TypeEnum,
        _1: '1' as TypeEnum,
        _2: '2' as TypeEnum,
        _3: '3' as TypeEnum,
        _4: '4' as TypeEnum,
        _5: '5' as TypeEnum,
        _6: '6' as TypeEnum
    };
}


