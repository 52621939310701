/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<NewUserRequest, \'id\' | \'auth2FASecret\' | \'auth2FAIV\' | \'createdDate\'>, schemaOptions: { title: \'NewUserRequestExcluded\', exclude: [ \'id\', \'auth2FASecret\', \'auth2FAIV\', \'createdDate\' ] })
 */
export interface NewUserRequestExcluded { 
    email: string;
    firstname?: string;
    lastname?: string;
    role?: string;
    photo?: string | null;
    phone?: string | null;
    note?: string | null;
    active?: boolean;
    auth2FAEnabled?: boolean;
    password1: string;
    password2: string;
}

