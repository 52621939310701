/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<Gallery, \'id\'>, schemaOptions: { title: \'NewGallery\', exclude: [ \'id\' ] })
 */
export interface NewGallery { 
    name: string;
    code: string;
    newsId?: number;
    created?: string;
    visible: boolean;
}

