/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface Exhibition { 
    id?: number;
    auctionId?: number;
    exhibitionFrom: string;
    exhibitionTo: string;
    mainExhibition?: boolean;
    matterportId?: string | null;
    youtubeVideoId?: string | null;
    archived?: boolean;
    status?: number;
    visible?: boolean;
    showPrices?: boolean | null;
    created?: string;
    lastModified?: string | null;
}

