/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



export interface UserFieldsOneOf { 
    id?: boolean;
    email?: boolean;
    firstname?: boolean;
    lastname?: boolean;
    role?: boolean;
    photo?: boolean;
    phone?: boolean;
    note?: boolean;
    active?: boolean;
    createdDate?: boolean;
    auth2FAEnabled?: boolean;
    auth2FASecret?: boolean;
    auth2FAIV?: boolean;
}

