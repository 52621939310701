/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Menu, schemaOptions: { includeRelations: false })
 */
export interface Menu { 
    id?: number;
    name: string;
    code: string;
    visible?: boolean;
    nested?: Array<object>;
}

