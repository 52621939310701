<h1 mat-dialog-title>Změnit pořadí prvků slideru<strong></strong></h1>
<div mat-dialog-content>

  <div class="mt-6 flex flex-col flex-wrap items-center justify-center" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!loading">
    <div class="flex flex-row items-center place-content-center" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
      <div *ngFor="let item of slider.items" class="m-2 w-1/6 max-h-56 cursor-pointer" cdkDrag>
        <img [src]="item.filename | photoProxy:'rs:fill:200:200:1/g:no' | async" [alt]="item.filename" class="" />
      </div>
    </div>
  </div>

</div>
<div mat-dialog-actions cdkTrapFocus class="flex flex-row-reverse">
  <button mat-flat-button color="accent"  class="m-2" (click)="save()">Uložit</button>
  <button mat-flat-button color="primary" class="m-2" (click)="dialogRef.close(false)" cdkFocusInitial>Zrušit</button>
</div>
