import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {LangsService} from '../../services/langs.service';
import {LangWithRelations} from "../../../../@backend";
import {UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-add-lang-dialog',
  templateUrl: './add-lang-dialog.component.html',
  styleUrls: ['./add-lang-dialog.component.scss']
})
export class AddLangDialogComponent {

  public langs: LangWithRelations[];
  form = this.fb.group({
    langId: [null, Validators.required]
  });


  constructor(
    public dialogRef: MatDialogRef<AddLangDialogComponent>,
    private langService: LangsService,
    private fb: UntypedFormBuilder,
  ) {
    this.langs = this.langService.getLangs();
  }

  addLang() {
    if(this.form.value.langId !== null) {
      this.dialogRef.close(this.form.value.langId);
    }else{
      // this.form.controls.langId.setErrors()
    }
  }
}
