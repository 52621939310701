/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemPictureExcludingIdWithRelations } from './itemPictureExcludingIdWithRelations';
import { ItemLangExcludingIdWithRelations } from './itemLangExcludingIdWithRelations';
import { AuthorExcludingIdWithRelations } from './authorExcludingIdWithRelations';
import { AuthorRecordsExcludingIdWithRelations } from './authorRecordsExcludingIdWithRelations';
import { ExhibitionExcludingIdWithRelations } from './exhibitionExcludingIdWithRelations';
import { ItemStatusExcludingIdWithRelations } from './itemStatusExcludingIdWithRelations';
import { AuctionExcludingIdWithRelations } from './auctionExcludingIdWithRelations';


/**
 * (tsType: Omit<ItemWithRelations, \'id\'>, schemaOptions: { exclude: [ \'id\' ], includeRelations: true })
 */
export interface ItemExcludingIdWithRelations { 
    auctionId?: number;
    exhibitionId?: number;
    size?: string | null;
    priceEstimationLow?: number | null;
    priceEstimationHigh?: number | null;
    priceCurrent?: number | null;
    priceHammer?: number | null;
    priceFinal?: number | null;
    startPriceForBidding?: number | null;
    startPriceForClosing?: number | null;
    catalogueNumber?: number | null;
    canvasId?: number | null;
    artslimitId?: number | null;
    created?: string;
    lastModified?: string;
    status?: number;
    auction?: AuctionExcludingIdWithRelations;
    foreignKey?: any | null;
    exhibition?: ExhibitionExcludingIdWithRelations;
    lang?: ItemLangExcludingIdWithRelations;
    langs?: Array<ItemLangExcludingIdWithRelations>;
    pictures?: Array<ItemPictureExcludingIdWithRelations>;
    authors?: Array<AuthorExcludingIdWithRelations>;
    itemStatus?: ItemStatusExcludingIdWithRelations;
    record?: AuthorRecordsExcludingIdWithRelations;
}

