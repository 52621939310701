/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorLangExcludingIdCreatorCreatedLangWithRelations } from './authorLangExcludingIdCreatorCreatedLangWithRelations';
import { ItemExcludingIdCreatorCreatedLangWithRelations } from './itemExcludingIdCreatorCreatedLangWithRelations';


/**
 * (tsType: Omit<AuthorWithRelations, \'id\' | \'creator\' | \'created\' | \'lang\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\', \'creator\', \'created\', \'lang\' ] })
 */
export interface AuthorExcludingIdCreatorCreatedLangWithRelations { 
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string;
    birthday?: string | null;
    death?: string | null;
    canvasId?: number | null;
    lang?: AuthorLangExcludingIdCreatorCreatedLangWithRelations;
    items?: Array<ItemExcludingIdCreatorCreatedLangWithRelations>;
    langs?: Array<AuthorLangExcludingIdCreatorCreatedLangWithRelations>;
}

