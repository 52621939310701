/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<BlogPostCategoryLangWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface BlogPostCategoryLangExcludingIdWithRelations { 
    langId?: number;
    blogPostCategoryId?: number;
    name: string;
    description?: string | null;
}

