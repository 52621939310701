/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorRecordsWithRelations } from './authorRecordsWithRelations';


export interface AuthorRecordsResult { 
    countTotal?: number;
    results?: Array<AuthorRecordsWithRelations>;
}

