/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AuthorLangPartialExcludingAuctionIdWithRelations } from './authorLangPartialExcludingAuctionIdWithRelations';
import { ItemPartialExcludingAuctionIdWithRelations } from './itemPartialExcludingAuctionIdWithRelations';


/**
 * (tsType: Omit<Partial<AuthorWithRelations>, \'auctionId\'>, schemaOptions: { includeRelations: true, exclude: [ \'auctionId\' ], partial: true })
 */
export interface AuthorPartialExcludingAuctionIdWithRelations { 
    id?: number;
    firstname?: string | null;
    lastname?: string | null;
    nickname?: string;
    birthday?: string | null;
    death?: string | null;
    canvasId?: number | null;
    created?: string;
    lang?: AuthorLangPartialExcludingAuctionIdWithRelations;
    items?: Array<ItemPartialExcludingAuctionIdWithRelations>;
    langs?: Array<AuthorLangPartialExcludingAuctionIdWithRelations>;
}

