/**
 * kodl-backend-api
 * REST API for galeriekodl.cz
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: AuctionFileLangWithRelations, schemaOptions: { includeRelations: true })
 */
export interface AuctionFileLangWithRelations { 
    id?: number;
    fileId: number;
    langId?: number;
    filename: string;
}

