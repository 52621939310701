import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class ItemService {

  // public statusesCode: object = {1: 'pre', 2: 'checking', 3: 'ready', 4: 'published', 5: 'bidding', 6: 'bstate', 7: 'closing', 8: 'terminated', 9: 'closed', 10: 'cancelled'};
  public statusesObjectCz: object = {
    1: 'V přípravě',
    2: 'Kontrola',
    3: 'Připraveno',
    4: 'Přiřazeno',
    5: 'Staženo z aukce',
    6: 'Prodáno',
    7: 'Prodej dokončen',
    8: 'Neprodáno',
    9: 'Vráceno',
    10: 'Zmařená aukce'
  };
  public statusesCz: any[] = [
    {index: 1, name: 'V přípravě'},
    {index: 2, name: 'Kontrola'},
    {index: 3, name: 'Připraveno'},
    {index: 4, name: 'Přiřazeno'},
    {index: 5, name: 'Staženo z aukce'},
    {index: 6, name: 'Prodáno'},
    {index: 7, name: 'Prodej dokončen'},
    {index: 8, name: 'Neprodáno'},
    {index: 9, name: 'Vráceno'},
    {index: 10, name: 'Zmařená aukce'},
  ];

  // V přípravě
  // Kontrola
  // Připraveno
  // Přiřazeno
  // Staženo z aukce
  // Prodáno
  // Prodej dokončen
  // Neprodáno
  // Vráceno
  // Zmařená aukce

  // pre
  // checking
  // ready
  // published
  // bidding
  // bstate
  // closing
  // terminated
  // closed
  // cancelled
  // foiled

  constructor() {
    this.init();
  }

  async init() {
  }
  getAllCzStatues(): object[] {
    return this.statusesCz;
  }
  getCzStatus(id: number) {
    return this.statusesObjectCz[id];
  }
  // getStatusCode(id: number): string {
  //   return this.statusesCode[id];
  // }

}
