<div class="m-3">
<div class="flex items-center" mat-dialog-title>
  <h2 *ngIf="isCreateMode()" class="headline m-0 flex-auto">Nový autor</h2>
  <h2 *ngIf="isUpdateMode()" class="headline m-0 flex-auto">Úprava autora</h2>

  <button [matMenuTriggerFor]="moreAuthor" class="text-secondary" mat-icon-button type="button">
    <mat-icon svgIcon="mat:more_vert"></mat-icon>
  </button>

  <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
  </div>
  <mat-dialog-content>
    <div *ngIf="loading" class="mt-6 flex flex-col flex-wrap items-center justify-center">
      <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!loading">
  <mat-divider class="text-border"></mat-divider>

      <div *ngIf="author.canvasId === null" class="flex flex-col p-2 mb-2 bg-amber text-contrast-black">
        <div class="flex flex-row items-center">
          <mat-icon svgIcon="mat:warning"></mat-icon>
          <h3 class="font-bold">Autor není spárovaný s Canvasem</h3>
        </div>
        <div class="mt-2">
          <mat-form-field class="w-1/3">
            <mat-label>Vyberte autora z Canvasu</mat-label>
            <input matInput [formControl]="canvasAuthorFilter" (input)="filter($event)" (focus)="filter($event)" type="text" placeholder="Vyberte autora"  aria-label="Vyberte autora" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectAuthor($event)">
              <mat-option *ngFor="let author of canvasAuthorsFiltered" [value]="author">
                <kodl-single-author [author]="author" [showDating]="true"></kodl-single-author>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </div>

    <form (ngSubmit)="save()" [formGroup]="form">
    <div class="flex flex-col sm:flex-row gap-1">
        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Jméno</mat-label>
          <input matInput formControlName="firstname">
          <mat-icon class="mr-2" matPrefix svgIcon="mat:person"></mat-icon>
      </mat-form-field>

        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Přijmení</mat-label>
          <input matInput formControlName="lastname">
          <mat-icon class="mr-2" matPrefix svgIcon="mat:person"></mat-icon>
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Zobrazované jméno</mat-label>
          <input matInput formControlName="nickname">
          <mat-icon class="mr-2" matPrefix svgIcon="mat:account_circle"></mat-icon>
      </mat-form-field>
    </div>

      <div class="flex flex-col sm:flex-row gap-1">
        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Datum narození</mat-label>
          <input matInput formControlName="birthday">
          <mat-icon class="mr-2" matPrefix svgIcon="mat:child_friendly"></mat-icon>
        </mat-form-field>

        <mat-form-field class="flex-auto" appearance="fill">
          <mat-label>Datum úmrtí</mat-label>
          <input matInput formControlName="death">
          <mat-icon class="mr-2" matPrefix svgIcon="mat:remove"></mat-icon>
        </mat-form-field>
      </div>
    </form>

      <div class="flex-auto">
        <mat-tab-group mat-stretch-tabs>
          <mat-tab *ngFor="let lang of author.langs">
            <ng-template matTabLabel>
              <mat-icon svgIcon="flag:{{langsService.getLangsShortCode(lang.langId)}}"></mat-icon>
              <span class="pl-2">{{langsService.getLangsShortCode(lang.langId)}}</span>
            </ng-template>
            <ng-template matTabContent>
              <div class="pt-2 flex flex-row sm:flex-col">
                <h3 class="font-bold">Popis</h3>
                <editor class="w-full"
                        [(ngModel)]="lang.description"
                        [init]="tinyService.modulesEasy()">
                </editor>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close type="button">Zavřít</button>
    <button color="primary" mat-flat-button (click)="save()" [disabled]="!isValid()"><span *ngIf="isCreateMode()">Vytvořit autora</span><span *ngIf="isUpdateMode()">Uložit autora</span></button>
  </mat-dialog-actions>

</div>

<mat-menu #moreAuthor="matMenu" xPosition="before" yPosition="below">
<!--    <button mat-menu-item (click)="updateFromCanvas()">-->
<!--      <mat-icon svgIcon="mat:cloud_queue"></mat-icon>-->
<!--      <span>Aktualizovat z Canvasu</span>-->
<!--    </button>-->
<!--  <button mat-menu-item (click)="delete()">-->
<!--    <mat-icon svgIcon="mat:delete"></mat-icon>-->
<!--    <span>Smazat</span>-->
<!--  </button>-->

</mat-menu>
